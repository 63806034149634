import { Component } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent {
  _SESSION_ID_IBD_: string = '';

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.getKey('_SESSION_ID_IBD_');
  }

  updateKey(key: string) {
    if (!this[key].length) {
      return this.alertService.error('Key incorrecta.')
    }

    this.apiService.updateConfig(key, this[key]).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se ha podido actualizar la key.');
      }

      this.getKey('_SESSION_ID_IBD_');
      this.alertService.success('Key actualizado.');
    });
  }

  getKey(key: string) {
    this.apiService.getConfig(key).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this[key] = result;
    });
  }
}
