<div *ngIf="hasProducts()">
  <div class="filters">
    <div class="autocomplete-relative">
      <div class="autocomplete-element">
        <app-autocomplete (valueChange)="selectProduct($event)" [newProducts]="true" [brand]="brand"></app-autocomplete>
      </div>
      <app-search-brand (valueChange)="onSelectBrand($event)" class="form-auto"></app-search-brand>
    </div>
  </div>
  <div>
    <div class="left-element">
      <div mat-card-avatar *ngIf="search.image.length" style="height: 200px;"><img style="max-height: -webkit-fill-available;" [src]="search.image" [alt]="search.ref" /></div>
      <div mat-card-avatar *ngIf="!search.image.length" style="height: 200px;"><img style="max-height: -webkit-fill-available;" src="https://www.orbitadigital.com/img/p/es-default-large_default.jpg" [alt]="search.ref" /></div>
      <form class="form">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Referencia</mat-label>
          <input matInput placeholder="Referencia" [(ngModel)]="search.ref" [ngModelOptions]="{standalone: true}" (change)="validateRef()" autocomplete="off">
        </mat-form-field>
      </form>

      <div *ngIf="notAction == true && itemSelected && itemSelected.is_parent == 0" class="alert-danger">
        La marca {{itemSelected.brand}} no es una marca multiproveedor.
      </div>

      <div *ngIf="itemSelected && itemSelected.is_parent == 1 && !itemSelected.ref.length">
        <button mat-raised-button class="button-margin" (click)="desactiveParent()">Desactivar padre</button>
      </div>

      <div *ngIf="notAction == false && itemSelected && itemSelected.is_parent == 0 && !itemSelected.ref.length && search.products && search.products.length < 2">
        <button mat-raised-button class="button-margin" (click)="activeParent()">Activar padre</button>
      </div>

      <div *ngIf="search.url_product.length" [ngStyle]="{'margin-top': '10px'}"><a target="_blank" [href]="search.url_product">Ir a prestashop</a></div>
      <div *ngIf="search.url_odoo.length" [ngStyle]="{'margin-top': '10px'}"><a target="_blank" [href]="search.url_odoo">Ir a odoo</a></div>
    </div>

    <div class="right-element">
      <app-table [url_image] = "search.image" [products] = "search.products" [newProducts] = "newProducts" [deleted] = "newSuggestions" [type]="'products'" [notAction]="notAction" [isMulti]="is_multi" [ref]="search.ref" [changedRef]="activeBoton" (addProducts)="add($event, 'suggestions')" (updateRef)="updateReference($event)" (unLink)="clear()" (link)="updateData()" style="margin: 10px;"></app-table>
      <app-table [url_image] = "''" [products] = "search.suggestions" [newProducts] = "newSuggestions" [deleted] = "newProducts" [type]="'suggestions'" [notAction]="notAction" [isMulti]="false" [ref]="search.ref" (addProducts)="add($event, 'products')" (searchProduct)="searchProduct($event)" style="margin: 10px;"></app-table>

      <div id="control_errors">
        <p *ngFor="let supplier of search.control_errors" class="alert-danger">No se ejecuta hoy correctamente el script de <a [href]="supplier.url" target=”_blank”>{{ supplier.name }}</a></p>
      </div>
    </div>
  </div>
</div>
