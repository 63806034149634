<form>
  <div class="mat-subheading-2">{{title}}</div>

  <mat-form-field class="filter-input">
    <input matInput type="text" [(ngModel)]="value" name="input" autocomplete=off>
  </mat-form-field>

  <div class="actions">
    <button mat-button type="button" color="primary" (click)="onCancel()">CANCELAR</button>
    <button mat-button type="submit" color="primary" (click)="onSave()">GUARDAR</button>
  </div>
</form>
