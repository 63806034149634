import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-data',
  templateUrl: './edit-data.component.html',
  styleUrls: ['./edit-data.component.scss']
})
export class EditDataComponent implements OnChanges {
  public ds: string[];
  public fileContent: any;
  public itemSelected: string;
  public fileChanged: any;
  public test: any;
  public paramn: string;
  public rule: string = '';

  @Input() title: string;
  @Input() functionDs: string;
  @Input() functionDataFile: string;
  @Input() functionUpdate: string;

  constructor(private apiService: ApiService, private alertService: AlertService, private route: ActivatedRoute, private router: Router) {
    this.test = false;

    this.paramn = this.route.snapshot.paramMap.get('item');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.functionDs && changes.functionDs.firstChange) {
      this.getList();
      if (this.paramn) {
        this.setFile(this.paramn);
      }
    }
  }

  getList() {
    this.apiService[this.functionDs]().subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      if (result['result']) {
        this.ds = result['result'];
      } else {
        this.ds = result;
      }
    });
  }

  setFile(item: string) {
    this.apiService[this.functionDataFile](item).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.itemSelected = item;
      this.showData(result);
    });
  }

  showData(data) {
    let fileReader: FileReader = new FileReader();
    const self = this;

    fileReader.onloadend = function (x) {
      self.fileContent = fileReader.result;
      self.fileChanged = fileReader.result;
    }

    var oMyBlob = new Blob([...[data]], { type: 'text/plain' }); // the blob
    fileReader.readAsText(oMyBlob);
  }

  fileEdited(event) {
    this.fileChanged = event.target.innerText;
  }

  updateFile() {
    if (!this.itemSelected.length) {
      return this.alertService.error('No se selecciona un archivo');
    }

    if (this.fileChanged == this.fileContent) {
      return this.alertService.warn('No existen cambios');
    }

    this.apiService[this.functionUpdate](this.fileChanged, this.itemSelected).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.getList();
      this.fileChanged = this.fileContent;
    });
  }

  tryRule(allData = '0') {
    this.apiService.executeRule(this.itemSelected, allData).subscribe(data => {
      if (data['error']) {
        this.test = false;
        return this.alertService.error(data['error']);
      }

      if (data['messages']) {
        data['messages'].forEach(message => this.alertService.warn(message));
      }

      this.test = data;
    }, (error) => {
      this.apiService.validateError(error, " al procesar la regla.");
      this.test = error;
    });
  }

  createNewRule() {
    if (this.rule.length < 1) {
      return this.alertService.warn('Se necesita un nombre de regla');
    }

    this.apiService.createNewRule(this.rule).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.getList();
      this.rule = '';
    });
  }
}
