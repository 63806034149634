<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" opened class="drawer">
      <div class="num-products" *ngIf="ds">{{title}}: {{ds.length}}</div>
      <div class="list" *ngIf="ds">
          <cdk-virtual-scroll-viewport itemSize="51" class="viewport" uiVirtualScrollViewportResize>
              <div mat-list-item *cdkVirtualFor="let item of ds" class="item-viewport" [class.active]="item === itemSelected">
                  <div mat-line class="title-cdk" (click)="setFile(item)">
                      <div *ngIf="item">
                          <div class="item-name">
                              {{item}}
                          </div>
                      </div>
                      <div *ngIf="!item">
                          Loading...
                      </div>
                  </div>
              </div>
          </cdk-virtual-scroll-viewport>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <pre  id="edit-rule" *ngIf="itemSelected" [contentEditable]="true" (focusout)="fileEdited($event)">{{fileContent}}</pre>
      <div id="edit-actions">
        <div id="header">
          <h2>Acciones</h2>
          <div>
            <mat-form-field class="example-form-field" appearance="standard">
              <mat-label>Nombre de la regla</mat-label>
              <input matInput type="text" [(ngModel)]="rule" autocomplete="off">
            </mat-form-field>
            <button mat-raised-button (click)="createNewRule()" *ngIf="functionDs != 'getExceptions'">Crear</button>
          </div>
          <button mat-raised-button *ngIf="itemSelected && fileChanged != fileContent" (click)="updateFile()">Actualizar</button>
          <button mat-raised-button (click)="tryRule()" *ngIf="itemSelected && functionDs != 'getExceptions'">Prueba</button>
          <button mat-raised-button (click)="tryRule('1')" *ngIf="itemSelected && functionDs != 'getExceptions'">Prueba completa</button>
        </div>
        <div *ngIf="test" id="test">
          <pre>
            {{test | json}}
          </pre>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
