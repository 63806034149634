<form class="form" [formGroup]='form'>
    <div class="autocomplete">
        <mat-form-field class="filter-input">
            <input placeholder="Buscar nuevo producto..." matInput [matAutocomplete]="autoProduct" formControlName='autocompleteProduct' class="input-autocomplete">
        </mat-form-field>
        <mat-autocomplete autoActiveFirstOption #autoProduct="matAutocomplete" [displayWith]="displayProduct" (optionSelected)="onSelectProduct($event)">
            <mat-option *ngIf="isLoadingProducts" class="is-loading">
                <mat-spinner diameter="30"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isLoadingProducts">
                <mat-option *ngFor="let item of dataProducts" [value]="item" title="{{item.name}}" [innerHTML]="getName(item) | highlight: toHighlight"></mat-option>
            </ng-container>
        </mat-autocomplete>
    </div>
</form>
