<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.length > 0 ? dataSource.length - 1 : 0}} Excepciones</span>
  <span class="app-toolbar-filler"></span>
  <app-options-supplier (selectSupplier)="changeSupplier($event)" [supplier]="''" [discards]="[]" [news]="[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]"></app-options-supplier>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="Estado" class="toolbar-icon">filter_list</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <mat-label>Tipo</mat-label>
    <mat-select [(value)]="filter" (selectionChange)="updateTable()">
      <mat-option *ngFor="let item of filters" [value]="item"> {{ item | titlecase }} </mat-option>
    </mat-select>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="Estado" class="toolbar-icon">swap_horizontal_circle</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <mat-label>Filtro</mat-label>
    <mat-select [(value)]="type" (selectionChange)="updateTable()">
      <mat-option *ngFor="let item of types" [value]="item"> {{ item | titlecase }} </mat-option>
    </mat-select>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <mat-slide-toggle color="primary" (change)="changeToogle($event)" [checked]="active">{{active ? 'Activados' : 'Desactivados'}}</mat-slide-toggle>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="updateTable()"><mat-icon>refresh</mat-icon>Actualizar</button>
  </mat-toolbar>
  <table mat-table *ngIf="dataSource" [dataSource]="dataSource" id="table-exceptions">
    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="col !== 'actions'" [matTooltip]="matTooltip[col]">
          {{translate[col]}}
        </span>
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="col" *ngIf="element.id > 0">
          <div *ngSwitchCase="'actions'" >
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="updateActive(element, false)" *ngIf="element.active == '1'">
                <mat-icon>settings_power</mat-icon>Desactivar
              </button>
              <button mat-menu-item (click)="updateActive(element, true)" *ngIf="element.active == '0'">
                <mat-icon>settings_power</mat-icon>Activar
              </button>
              <button mat-menu-item (click)="deleteException(element)">
                <mat-icon>link_off</mat-icon>Eliminar
              </button>
              <button mat-menu-item (click)="updateExceptionValue(element)" *ngIf="element.type != 'desactivar'">
                <mat-icon>update</mat-icon>Actualizar
              </button>
            </mat-menu>
          </div>
          <div *ngSwitchCase="'value'">
            <mat-form-field class="example-form-field" appearance="standard" *ngIf="element.type == 'mantener' || element[col] != ''">
              <mat-label>{{translate[col]}}</mat-label>
              <input matInput type="text" [(ngModel)]="element[col]" autocomplete="off">
            </mat-form-field>
          </div>
          <span *ngSwitchDefault>
            {{element[col]}}
          </span>
        </div>
        <div [ngSwitch]="col" *ngIf="element.id == 0">
          <div *ngSwitchCase="'actions'">
            <button mat-raised-button matTooltip="Añadir nueva excepcion" (click)="add(element)"><mat-icon>add_box</mat-icon></button>
          </div>
          <div *ngSwitchCase="'supplier'">
            <app-options-supplier (selectSupplier)="element.supplier = $event" [supplier]="''" [discards]="[]" [news]="[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]"></app-options-supplier>
          </div>
          <div *ngSwitchCase="'type_filter'">
            <mat-form-field appearance="standard" class="small-form-field">
              <mat-label>Tipo</mat-label>
              <mat-select [(value)]="element[col]">
                <mat-option *ngFor="let item of filters" [value]="item"> {{ item | titlecase }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'type'">
            <mat-form-field appearance="standard" class="small-form-field">
              <mat-label>Filtro</mat-label>
              <mat-select [value]="element[col]" (selectionChange)="selectFilter($event, element)">
                <mat-option *ngFor="let item of types" [value]="item"> {{ item | titlecase }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'id'">
            {{element[col]}}
          </div>
          <span *ngSwitchDefault>
            <mat-form-field class="example-form-field" appearance="standard">
              <mat-label>{{translate[col]}}</mat-label>
              <input matInput type="text" [(ngModel)]="element[col]" autocomplete="off">
            </mat-form-field>

            <div *ngIf="col == 'value' && element.type == 'mantener'">
              <mat-form-field appearance="standard" class="small-form-field" id="without-margin">
                <mat-label>Campos de producto</mat-label>
                <mat-select (selectionChange)="selectFieldProduct($event, element)">
                  <mat-option *ngFor="let item of filedsProduct" [value]="item"> {{ item | titlecase }} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
