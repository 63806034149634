<h1 mat-dialog-title>{{data.column}}</h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="header">
    <mat-label>Renombrar header</mat-label>
    <input matInput [value]="getNewHeader()" (change)="updateNewHeader($event)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button (click)="addNew()" cdkFocusInitial *ngIf="header && !isUpdated()">Crear</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial *ngIf="header && isUpdated()">Actualizar</button>
</div>
