<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.total}} productos</span>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="updateTable()">
        <mat-icon>refresh</mat-icon>Actualizar
  </button>
  <span class="app-toolbar-filler"></span>
  <app-search-brand (valueChange)="onSelectBrand($event)" class="form-auto"></app-search-brand>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="filter" class="toolbar-icon">filter_list</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <input matInput placeholder="Filtro" #filter>
    <button mat-button [disabled]="filter.value.length == 0" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>
  </mat-toolbar>
  <mat-table class="app-reltable dense" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="ref">
      <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Referencia</mat-header-cell>
      <mat-cell class="id-cell" *matCellDef="let item">{{item.ref}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="id_ps">
      <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Id Prestashop</mat-header-cell>
      <mat-cell class="id-cell" *matCellDef="let item" [satPopoverAnchor]="popover" (click)="popover.open()">
        {{item.id_ps}}
        <sat-popover #popover hasBackdrop xAlign="start" yAlign="start" (closed)="save(item, $event, 'id_ps')">
          <app-inline-edit [value]="item.id_ps" [title]="'Id Prestashop'"></app-inline-edit>
        </sat-popover>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="id_odoo">
      <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Id en Odoo</mat-header-cell>
      <mat-cell class="name-cell" *matCellDef="let item" [satPopoverAnchor]="popover" (click)="popover.open()">
        {{item.id_odoo}}
        <sat-popover #popover hasBackdrop xAlign="start" yAlign="start" (closed)="save(item, $event, 'id_odoo')">
          <app-inline-edit [value]="item.id_odoo" [title]="'Id en Odoo'"></app-inline-edit>
        </sat-popover>
      </mat-cell>
    </ng-container>

    <!-- TODO menu para desvincular etc -->
    <!-- <ng-container matColumnDef="menu">
      <mat-header-cell class="menu-cell" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="menu-cell" *matCellDef="let item">
        <button mat-button [matMenuTriggerFor]="menu"
          [disabled]="!((item.ps_id > 0 && capabilities.syncToOdoo) || (item.odoo_id > 0 && capabilities.syncToPrestashop) || (item.ps_id > 0 && item.odoo_id > 0))">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="sync(item, 'odoo')" *ngIf="item.ps_id > 0 && capabilities.syncToOdoo">
            <mat-icon>sync</mat-icon>Sincronizar a Odoo
          </button>
          <button mat-menu-item (click)="sync(item, 'prestashop')" *ngIf="item.odoo_id > 0 && capabilities.syncToPrestashop">
            <mat-icon>sync</mat-icon>Sincronizar a Prestashop
          </button>
          <button mat-menu-item (click)="unlink(item)" *ngIf="item.ps_id > 0 && item.odoo_id > 0">
            <mat-icon>link_off</mat-icon>Desvincular
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container> -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"></mat-row>

  </mat-table>

  <mat-paginator *ngIf="dataSource" [length]="dataSource.total" pageSize="10" showFirstLastButtons>
  </mat-paginator>
