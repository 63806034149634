<mat-toolbar color="secundary" class="app-toolbar">

  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(true)">add_box</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <mat-label>Proveedor</mat-label>
    <mat-select (selectionChange)="changeSupplier($event)" [value]="supplier">
      <mat-option *ngFor="let option of suppliers" [value]="option">{{option.name | titlecase}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && supplier.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(false)">update</mat-icon>

  <span class="app-toolbar-filler"></span>
  
  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir archivo'" class="toolbar-icon" (click)="openTarifa(true)" *ngIf="supplier">add_box</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field" *ngIf="supplier && supplier.id > 0">
    <mat-label>Archivo</mat-label>
    <mat-select (selectionChange)="changeFile($event)" [value]="file">
      <mat-option *ngFor="let option of files" [value]="option">{{option.file}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && file && file.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar archivo'" class="toolbar-icon" (click)="openTarifa(false)">update</mat-icon>

  <span class="app-toolbar-filler"></span>

  <mat-form-field appearance="standard" class="small-form-field" *ngIf="supplier && supplier.id > 0">
    <mat-select (selectionChange)="changetype($event)" [value]="type">
      <mat-option value="odoo">Odoo</mat-option>
      <mat-option value="prestashop">Prestashop</mat-option>
    </mat-select>
  </mat-form-field>
  
  <span class="app-toolbar-filler"></span>
  <mat-icon (click)="openInfo()">info</mat-icon>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-spinner class="spinner-load" *ngIf="isLoading"></mat-spinner>

    <table *ngIf="dataSource && !isLoading" mat-table matSort (matSortChange)="announceSortChange()" [dataSource]="dataSource">
      <ng-container [matColumnDef]="column" *ngFor="let column of header; let i = index">
        <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} <mat-icon (click)="openColumn(i, column)">extension</mat-icon> </th> -->
        
        <th mat-header-cell *matHeaderCellDef>
          <span mat-sort-header arrowPosition="before">{{column}}</span>
          <mat-icon (click)="openColumn(i, column)">extension</mat-icon>
        </th>
  
        <td mat-cell *matCellDef="let element" [innerHTML]="element[column]"> {{element[column]}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="header"></tr>
      <tr mat-row *matRowDef="let row; columns: header;"></tr>
    </table>
  </mat-drawer-container>
</mat-sidenav-container>
