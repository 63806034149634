import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { Supplier } from '../types/supplier.model';

@Component({
  selector: 'app-options-supplier',
  templateUrl: './options-supplier.component.html',
  styleUrls: ['./options-supplier.component.scss']
})
export class OptionsSupplierComponent implements OnInit {
  public suppliers: Supplier[] = [];
  @Output() selectSupplier: EventEmitter<string> = new EventEmitter();
  @Input() discards: string[];
  @Input() news: Supplier[];
  @Input() supplier: string;

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.getSuppliers();
  }

  getSuppliers() {
    this.apiService.getSuppliers().subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al obtener proveedores de la base de datos');
      }

      var supp = data;
      if (this.discards.length) {
        supp = data.filter(s => this.discards.indexOf(s.name) === -1);
      }
      if (this.news.length) {
        this.news.forEach(p => supp.unshift(p));
      }

      this.suppliers = [...supp];
    });
  }

  changeSupplier(event: MatSelectChange) {
    if (this.supplier == event.value) {
      return;
    }

    this.supplier = event.value;
    this.selectSupplier.emit(event.value);
  }
}
