import { Component, OnInit } from '@angular/core';
import { ProductItem, SearchProducts } from 'src/app/types/product-item-model';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { SupplierComponent } from '../supplier/supplier.component';
import { FileTarifa, SupplierTarifa } from 'src/app/types/supplier-tarifa.model';
import { FileComponent } from '../file/file.component';
import { HeaderComponent } from '../header/header.component';
import { InfoComponent } from '../info/info.component';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  public supplier: SupplierTarifa;
  public suppliers: SupplierTarifa[];
  public files: FileTarifa[] = [];
  public file: FileTarifa;
  public data: any[] = undefined;
  public header: string[];
  public isLoading: boolean = false;


  public filter: string = '';
  public search: SearchProducts;
  public itemSelected: ProductItem;
  public type: string = '0';
  public brand: string = '';
  public toHighlight: string = '';
  public load: boolean;
  public descatalogados: boolean = false;
  public stock: boolean = true;

  constructor(private apiService: ApiService, private alertService: AlertService, public dialog: MatDialog) {
    this.type = '0';
    this.load = true;
    this.brand = '';

    this.apiService.getSuppliersTarifa().subscribe(result => {
      if (!result.length) {
        return this.alertService.error("No existen proveedores");
      }

      this.suppliers = result;
    });
  }

  ngOnInit(): void { }

  changeSupplier(event: MatSelectChange) {
    if (this.supplier == event.value) {
      return;
    }

    this.supplier = event.value;
    this.getFilesTarifa();
  }

  changeFile(event: MatSelectChange) {
    if (this.file == event.value) {
      return;
    }

    this.file = event.value;
    this.getDataTarifa();
  }

  getDataTarifa() {
    this.isLoading = true;
    this.apiService.getDataTarifa(this.supplier.id, this.file.id).subscribe(result => {
      this.isLoading = false;
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.header = result['header'];
      this.data = result['data'];
    });
  }

  getFilesTarifa() {
    this.apiService.getFilesTarifa(this.supplier.id).subscribe(result => {
      if (!result.length) {
        return this.alertService.error("No existen proveedores");
      }

      this.files = result;
    });
  }


  openSupplier(add: boolean = true) {
    if (add) {
      var add_data = {
        id: 0,
        name: '',
        categ_id: 0,
        brand_id: 0,
        supplier_id: 0,
        search_odoo: '',
        search_prestashop: '',
        date_add: '',
        date_upd: ''
      }
    }

    console.log(add);

    const dialogRef = this.dialog.open(SupplierComponent, {
      width: '250px',
      data: add ? add_data : this.supplier
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openColumn(id_column: number, column: string) {
    const dialogRef = this.dialog.open(HeaderComponent, {
      width: '250px',
      data: {
        id_column: id_column + 1,
        id_file: this.file.id,
        column
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.header) {
        this.getDataTarifa();
      }
    });
  }

  openInfo() {
    this.dialog.open(InfoComponent, {
      width: '250px'
    });
  }


  openTarifa(add: boolean = true) {
    if (add) {
      var add_data = {
        id: 0,
        id_supplier: this.supplier.id,
        file: '',
        prefix: '',
        dto: 50,
        update_ean: false,
        update_price: false,
        update_whosale_price: false,
        update_category: false,
        date_add: '',
        date_upd: '',

      }
    }

    const dialogRef = this.dialog.open(FileComponent, {
      width: '450px',
      data: add ? add_data : this.file
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getFilesTarifa();
    });
  }

  productSelect(product: ProductItem) {
    this.itemSelected = product;
  }

  onSelectBrand(event) {
    this.brand = event;
  }

  clear() {
    this.load = false;

    setTimeout(() => {
      this.load = true;
    }, 60);
  }

  newSearchProduct(element: ProductItem) {
    this.itemSelected = element;
  }

}
