import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { Supplier } from '../types/supplier.model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  public dataSource: Supplier[] = [];
  public displayedColumns: string[] = ['id', 'name', 'id_ps', 'id_odoo', 'id_cat_odoo', 'url_cron', 'isEdit'];
  public dataSchema = {
    'id': 'number',
    'name': 'text',
    'id_ps': 'number',
    'id_odoo': 'number',
    'id_cat_odoo': 'number',
    'url_cron': 'text',
    'isEdit': 'isEdit'
  };
  public translate = {
    'id': 'Id',
    'name': 'Proveedor',
    'id_ps': 'Id prestashop',
    'id_odoo': 'Id odoo',
    'id_cat_odoo': 'Id categoria odoo',
    'url_cron': 'Url de ejecución',
    'isEdit': 'isEdit'
  };

  public matTooltip = {
    'id': 'Id',
    'name': 'Proveedor',
    'id_ps': 'Id prestashop',
    'id_odoo': 'Id odoo',
    'id_cat_odoo': 'Id categoria odoo',
    'url_cron': 'Ejemplo: "https://www.orbitadigital.com/ruta_proveedor/index.php@=" donde "@=" es la parte que falta de cada uno',
    'isEdit': ''
  };

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.getSuppliers();
  }

  ngOnInit() {

  }

  getSuppliers() {
    this.apiService.getSuppliers().subscribe(data => {
      if (!data) {
        return this.alertService.error('Error al obtener proveedores de la base de datos');
      }

      data.push({
        'id': 0,
        'name': '',
        'id_ps': 0,
        'id_odoo': 0,
        'id_cat_odoo': 0,
        'url_cron': '',
      });
      this.dataSource = [...data];
    });
  }

  updateSupplier(supplier: Supplier) {
    var errors = this.validateIfEmpty(supplier);

    if (errors.length) {
      this.alertService.error(errors[0]);
      return true;
    }

    const params = {
      action: 'updateSupplier',
      id: `${supplier.id}`,
      name: supplier.name,
      id_ps: `${supplier.id_ps}`,
      id_odoo: `${supplier.id_odoo}`,
      id_cat_odoo: `${supplier.id_cat_odoo}`,
      url_cron: `${supplier.url_cron}`
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (data !== true) {
        this.alertService.error('No se ha podido actualizar el proveedor ' + supplier.name);
      } else {
        this.alertService.success('Se actualiza correctamente el proveedor ' + supplier.name);
      }

      return false;
    });
  }

  deleteSupplier(id: number) {
    if (id < 1) {
      return this.alertService.error('El proveedor no es correcto');
    }

    const params = {
      action: 'deleteSupplier',
      id: `${id}`
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido eliminar el nuevo proveedor');
      }

      this.dataSource.forEach((supplier, index) => {
        if (supplier.id == id) {
          this.dataSource.splice(index, 1);
          this.dataSource = [...this.dataSource];
        }
      });
    });
  }

  validateIfEmpty(supplier: Supplier) {
    var errors: string[] = [];
    Object.keys(supplier).map((key) => {
      if (key == 'id' || key == 'isEdit' || key == 'id_ps' || key == 'url_cron') {

      } else {
        if (!supplier[key] || !supplier[key].trim()) {
          errors.push('El campo "' + this.translate[key] + '" está vacio');
        }
      }
    });

    return errors;
  }

  addSupplier(supplier: Supplier) {
    var errors = this.validateIfEmpty(supplier);

    if (errors.length) {
      if (errors.length == 3) {
        return false;
      }

      return this.alertService.error(errors[0]);
    }

    const params = {
      action: 'addSupplier',
      name: supplier.name,
      id_ps: `${supplier.id_ps}`,
      id_odoo: `${supplier.id_odoo}`,
      id_cat_odoo: `${supplier.id_cat_odoo}`,
      url_cron: `${supplier.url_cron}`
    };

    // Se reutiliza, no poner Type en la funcion
    this.apiService.actionsBrand(params).subscribe(data => {
      if (data.error) {
        return this.alertService.error(data.error);
      }

      if (!data) {
        return this.alertService.error('No se ha podido añadir el nuevo proveedor');
      }

      this.getSuppliers();
    });
  }

  openLink(url) {
    window.open(url, "_blank");
  }

  addSearchSupplier(e: Supplier) {
    if (e.id < 1) {
      return this.alertService.error('El proveedor no es correcto');
    }

    const params = {
      action: 'addSearchSupplier',
      id: `${e.id}`,
      value: '1'
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido realizar la acción.');
      }

      e.search = 1;
    });
  }

  deleteSearchSupplier(e: Supplier) {
    if (e.id < 1) {
      return this.alertService.error('El proveedor no es correcto');
    }

    const params = {
      action: 'deleteSearchSupplier',
      id: `${e.id}`
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido realizar la acción.');
      }

      e.search = null;
    });
  }
}
