<form class="form-auto">
  <mat-form-field class="form-auto-field">
    <input type="text" placeholder="Buscar marca" aria-label="Marca" matInput [formControl]="autoControl" [matAutocomplete]="auto" (input)="$event.target.value == '' ? setEmptyBrand() : true">
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="onSelectBrand($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" [innerHTML]="getNameBrand(option) | highlight: toHighlight"></mat-option>
    </mat-autocomplete>
    <button type="button" mat-button *ngIf="brand" matSuffix mat-icon-button aria-label="Clear" (click)="setEmptyBrand()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
