import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { finalize, switchMap, tap, debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductItem } from 'src/app/types/product-item-model';
import { HighlightPipe } from 'src/app/pipes/highlight.pipe';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autoProduct: MatAutocompleteTrigger;
  @Output() valueChange: EventEmitter<ProductItem> = new EventEmitter();
  form: FormGroup;
  isLoadingProducts = false;
  dataProducts: ProductItem[];
  toHighlight = '';
  selectedProduct: ProductItem;
  @Input() newProducts: boolean;
  @Input() brand: string;

  constructor(private apiService: ApiService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      autocompleteProduct: new FormControl()
    });

    this.resetAutocompleteProduct();

    this.form
      .get('autocompleteProduct')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoadingProducts = true),
        switchMap(value => this.apiService.loadDataSource(0, 8, '0', value, 1, this.brand, '', false, 0)
          .pipe(
            finalize(() => this.isLoadingProducts = false),
          )
        )
      )
      .subscribe((data) => {
        if (!data.result.length) {
          data.result.push({
            id: 0,
            is_parent: 0,
            ref: '',
            ref_supplier: 'No se encuentran datos',
            internal_ref_supplier: '',
            wholesale_price: 0,
            price: 0,
            stock: 0,
            qty_available: 0,
            supplier: '',
            brand: '',
            validated: false,
            id_product: 0,
            id_odoo: 0,
            name: '',
            date_add: '',
            data_validation: '',
            data_upd: ''
          })
        }

        this.dataProducts = data.result;
        this.toHighlight = data.search;
      });
  }

  displayProduct(searchResult: ProductItem) {
    if (searchResult) {
      if (!searchResult.supplier.length) {
        return searchResult.ref_supplier;
      }

      return searchResult.supplier[0].toUpperCase() + searchResult.supplier.slice(1) + ' - ' + searchResult.ref_supplier + ' - ' + searchResult.internal_ref_supplier + ' - ' + searchResult.name;
    }
  }

  getName(searchResult: ProductItem) {
    return this.displayProduct(searchResult);
  }

  resetAutocompleteProduct() {
    this.form.setValue({
      autocompleteProduct: ''
    });
  }

  onSelectProduct(event: MatAutocompleteSelectedEvent) {
    if (event.option.value.id > 0) {
      this.selectedProduct = event.option.value;
      this.valueChange.emit(this.selectedProduct);
      this.resetAutocompleteProduct();
    }
  }
}
