import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from '../services/alert.service';

export class LogsSource extends DataSource<string | undefined> {
  // private _pageSize = Math.ceil((window.screen.height || 1000) / 50);
  private _pageSize = Math.ceil((window.screen.height || 1000) / 60);
  private _cachedData = Array.from<string>({ length: 1 });
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(string | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();
  public _startPage: number;
  public _endPage: number;
  public length: number;
  public filter: string = '';
  public reference: string = '';
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private apiService: ApiService, private alertService: AlertService) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(string | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.pipe(debounceTime(300)).subscribe(range => {
      this._startPage = this._getPageForIndex(range.start);
      this._endPage = this._getPageForIndex(range.end - 1);
      for (let page = this._startPage; page <= this._endPage; page++) {
        this._fetchPage(page, this.filter, this.reference);
      }
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  setFilters(filter: string, reference: string, reload: boolean = false) {
    this._fetchPage(this._startPage, filter, reference, reload);
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  private _fetchPage(page: number, filter?: string, reference?: string, reload: boolean = false) {
    if (this.filter == filter && this.reference == reference && reload == false) {
      if (this._fetchedPages.has(page)) {
        return;
      }
    }

    this.filter = filter;
    this.reference = reference;
    this._fetchedPages.add(page);

    const offset = page * this._pageSize;
    const limit = offset + this._pageSize;
    const request = this.apiService.loadDataSourceByDir('logs', offset, limit, filter, reference);

    this.loadingSubject.next(true);

    request.subscribe(data => {
      this.loadingSubject.next(false);
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (this._cachedData.length !== data['total']) {
        this.length = data['total'];
        this._cachedData = Array.from({ length: data['total'] });
        this._fetchedPages = new Set<number>([page]);
      }

      this._cachedData.splice(offset, data['result'].length, ...data['result']);
      this._dataStream.next(this._cachedData);
    });

  }
}
