import { Component } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  title = 'Multi Supplier';
  mobileQuery: MediaQueryList;
  show = true;
  isLoading = false;
  isPro = false;
  showSubmenu: boolean = true;
  app_name = 'Gestor de Multiproveedores';
  titles = [
    { route: 'search', title: 'Productos' },
    { route: 'logs', title: 'Log de errores' },
    { route: 'new-brands', title: 'Marcas de diferentes proveedores' },
    { route: 'brands', title: 'Marcas multiproveedor' },
    { route: 'rules', title: 'Reglas' },
    { route: 'suppliers', title: 'Proveedores multiproveedor' },
    { route: 'parents', title: 'Productos padre' },
    { route: 'show-prices', title: 'Precios y stock del producto' }
  ];

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router, private apiService: ApiService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (window.location.hash.includes('show-prices')) {
      this.show = false;
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const t = this.titles.find(d => val.url.includes(d.route));

        if (typeof t != "undefined") {
          this.app_name = t.title;
        }
      }
    });

    this.isLoading = true;
    this.apiService.isPro().subscribe((val) => {
      this.isLoading = false;
      if (val === true) {
        this.isPro = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  openSite(siteUrl, type = '_blank') {
    window.open(siteUrl, type);
  }
}
