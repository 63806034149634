<mat-toolbar color="secundary" class="app-toolbar">
  <mat-form-field class="filter-input" style="padding-top: 20px;">
    <input type="text" matInput [(ngModel)]="filter" placeholder="Buscar producto" autocomplete="off">
    <button *ngIf="filter.length" matSuffix mat-icon-button aria-label="Clear" (click)="filter=''"><mat-icon>close</mat-icon></button>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="Proveedor" class="toolbar-icon" matTooltip="Filtrar por proveedor">assessment</mat-icon>
  <app-options-supplier (selectSupplier)="changeSupplier($event)" [discards]="[]" [news]="[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]" [supplier]="''"></app-options-supplier>
  <span class="app-toolbar-filler"></span>
  <app-search-brand (valueChange)="onSelectBrand($event)" class="form-auto"></app-search-brand>
  <span class="app-toolbar-filler" *ngIf="type == '0' && brand != ''"></span>
  <button mat-raised-button (click)="addToParents()" *ngIf="type == '0' && brand != ''">Añadir a padres</button>
  <span class="app-toolbar-filler" *ngIf="brand != 'Revisar'"></span>
  <mat-icon aria-hidden="false" aria-label="Type" class="toolbar-icon" *ngIf="brand != 'Revisar'">swap_horizontal_circle</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field" *ngIf="brand != 'Revisar'">
    <mat-select [(ngModel)]="type">
      <mat-option value="undefined">Todos</mat-option>
      <mat-option value="1">Vinculado</mat-option>
      <mat-option value="0">No vinculados</mat-option>
      <mat-option value="father">Padres</mat-option>
      <mat-option value="103">Outlet</mat-option>
      <mat-option value="123456789">Promociones</mat-option>
      <mat-option value="22">Duplicados</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <div>
    <mat-slide-toggle style="display: unset;" *ngIf="type == 'undefined' || type == '1' || type == '0' || type == 'father'" [(ngModel)]="descatalogados" color="primary">{{descatalogados ? 'Descatalogados' : 'No descatalogados'}}</mat-slide-toggle>
    <mat-slide-toggle style="display: unset;" [(ngModel)]="stock" color="primary">{{stock ? 'Con stock' : 'Sin stock'}}</mat-slide-toggle>
  </div>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" opened class="drawer">
      <app-list-products (valueChange)="productSelect($event)" [type]="type" [filter]="filter" [brand]="brand" [load]="load" [supplier]="supplier" [descatalogados]="descatalogados" [stock]="stock"></app-list-products>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <app-products [itemSelected]="itemSelected" (reload)="clear()" (newSearchProduct)="newSearchProduct($event)"></app-products>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
