<div class="num-products">Productos: {{ds.length}}</div>
<div class="list">
    <cdk-virtual-scroll-viewport itemSize="51" class="viewport" uiVirtualScrollViewportResize>
        <div mat-list-item *cdkVirtualFor="let item of ds; let i = index" class="item-viewport" [class.active]="isActiveSelected(item)" [style.background]="isParent(item)">
            <div mat-line class="title-cdk" (click)="setProduct(item, i)">
                <div *ngIf="item">
                    <div class="item-name" *ngIf="item.ref != '' && !item.ref_proc">
                        {{item.ref + ' - ' + item.ref_supplier + ' - ' + item.name | truncate:[95, '...']}}
                    </div>
                    <div class="item-name" *ngIf="item.ref == '' && !item.ref_proc">
                        {{item.ref_supplier + ' - ' + item.name | truncate:[95, '...']}}
                    </div>
                    <div class="item-name" *ngIf="item.ref_proc">
                        <b>{{item.ref_proc}}</b> - {{item.ref_supplier + ' - ' + item.name | truncate:[95, '...']}}
                    </div>
                </div>
                <div *ngIf="!item">
                    Loading...
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
