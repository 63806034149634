import { Component, OnInit } from '@angular/core';
import { CheckProduct, ProductItem, SearchProducts } from 'src/app/types/product-item-model';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyDown($event)'
  }
})
export class CheckComponent implements OnInit {
  public filter: string = '';
  public brand: string = '';
  public toHighlight: string = '';
  public descatalogados: boolean = false;
  public news_checks: boolean = true;
  public stock: boolean = true;
  public items: CheckProduct[];
  public activeElement: CheckProduct;
  public search: SearchProducts;
  public newProducts: ProductItem[] = [];
  public newSuggestions: ProductItem[] = [];

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.checkAllProducts();
  }

  ngOnInit(): void { }

  checkAllProducts() {
    this.apiService.checkAllProducts(this.filter, this.brand, this.descatalogados, this.stock, this.news_checks).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      if (!result.length) {
        this.items = [];
        return this.alertService.error('No se encuentran datos');
      }

      this.items = [...result];
    });
  }

  setProduct(product: CheckProduct) {
    if (this.activeElement != product) {
      this.activeElement = product;
      this.searchDataToValidate();
    }
  }

  onSelectBrand(event) {
    this.brand = event;
    this.checkAllProducts();
  }

  searchDataToValidate() {
    this.apiService.searchDataToValidate(this.activeElement.ids).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      if (!result.products.length && !result.suggestions.length) {
        return this.alertService.error('No se encuentran productos con los datos proporcionados');
      }

      this.search = result;
      if (!this.search.ref.length) {
        this.search.ref = this.activeElement.ref;
      }
    });
  }

  add(products: ProductItem[], where: string) {
    if (where == 'products') {
      this.newProducts = [...products];
    } else {
      this.newSuggestions = [...products];
    }
  }

  clear() {
    this.checkAllProducts();
  }

  updateData() {
    this.checkAllProducts();
    this.searchDataToValidate();
  }

  discardReference() {
    const ref = this.activeElement.reference;
    this.apiService.discardReference(ref, this.activeElement.ids).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.items.forEach(function (value) {
        if (value.reference == ref) {
          value.color = 'rgb(255, 235, 235)';
        }
      })
    });
  }

  handleKeyDown($event) {
    if ($event.keyCode !== 9) {
      return;
    }

    if (typeof this.activeElement == 'undefined' || !this.activeElement || this.items.length == 1) {
      this.setProduct(this.items[0]);
      return;
    }

    var x = false;
    for (let i = 0; i < this.items.length; i++) {
      if (x === true) {
        this.setProduct(this.items[i]);
        break;
      } else if (this.items[i].reference == this.activeElement.reference) {
        x = true;
      }
    }

    $event.preventDefault()
  }
}
