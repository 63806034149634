import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { LogsSource } from './logs-data-source.component';

@Component({
  selector: 'app-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.scss']
})
export class LogsListComponent implements OnInit {
  public ds;
  public itemSelected: string;
  public log;
  public supplier: string = 'hoy';
  public reference: string = '';
  public showProgressBar: boolean = false;

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.ds = new LogsSource(this.apiService, this.alertService);
    this.log = false;
    this.changeSupplier(this.supplier, this.reference);
  }

  ngOnInit(): void {
    this.ds.loading$.subscribe(d => this.showProgressBar = d);
  }

  setFile(item: string) {
    this.itemSelected = item;
    this.showProgressBar = true;

    this.apiService.getDocLogByFile(item).subscribe(data => {
      this.showProgressBar = false;
      if (data['error']) {
        this.log = false;
        return this.alertService.error(data['error']);
      }

      this.log = data;
    });
  }

  changeSupplier(supplier: string, reference: string, reload: boolean = false) {
    this.supplier = supplier;
    this.ds.setFilters(supplier, reference, reload);
  }
}
