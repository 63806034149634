import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { SearchProducts, ProductItem } from '../types/product-item-model';
import { Actions } from 'src/app/types/actions.model';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnChanges {
  @Input() itemSelected: ProductItem;
  public search: SearchProducts;
  public newProducts: ProductItem[] = [];
  public newSuggestions: ProductItem[] = [];
  private ref: string;
  public activeBoton: boolean = false;
  public notAction: boolean = false;
  @Output() reload: EventEmitter<ProductItem[]> = new EventEmitter();
  @Output() newSearchProduct: EventEmitter<ProductItem> = new EventEmitter();
  public brand: string = '';
  public toHighlight: string = '';
  public is_multi: boolean = false;

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemSelected) {
      this.notAction = false;
      this.activeBoton = false;
      this.newProducts = [];
      this.newSuggestions = [];

      if (typeof this.itemSelected == 'undefined') {
        return;
      }

      if (typeof this.search == 'undefined') {
        this.search = {};
      }

      this.updateProducts();
    }
  }

  public updateProducts() {
    this.apiService.loadDataByProduct(this.itemSelected.id, this.itemSelected.ref, this.itemSelected.brand).subscribe(data => {
      this.notAction = false;
      if (data.error) {
        if ("La marca " + this.itemSelected.brand + " no es una marca multiproveedor." == data.error) {
          this.notAction = true;
        }
        this.alertService.error(data.error);
      }

      this.search = data;
      this.search.is_multi = false;

      if (typeof this.search.ref == 'undefined') {
        if (this.itemSelected.ref.length) {
          this.search.ref = this.itemSelected.ref;
        } else {
          this.search.ref = this.itemSelected.ref_supplier;
        }
      } else {
        this.search.is_multi = true;
      }

      this.is_multi = this.search.is_multi;
      this.ref = this.search.ref;

      setTimeout(() => {
        console.log("aquiiiii", this.is_multi);
        this.is_multi = false;
      }, 1000);

      if (!this.search.products.length) {
        if (this.search.url_cron) {
          this.itemSelected.url_cron = this.search.url_cron;
        }

        if (this.search.url_cron_without_write) {
          this.itemSelected.url_cron_without_write = this.search.url_cron_without_write;
        }

        if (this.search.url_supplier) {
          this.itemSelected.url_supplier = this.search.url_supplier;
        }

        this.search.products = [this.itemSelected];
      }

      if (this.itemSelected.is_parent == 1 && !this.itemSelected.ref.length) {
        this.notAction = true;
      }

      this.validateRef();
    });
  }

  onSelectBrand(event) {
    this.brand = event;
  }

  hasProducts() {
    return typeof (this.search) !== "undefined" && typeof (this.search.products) !== "undefined";
  }

  selectProduct(event) {
    this.newProducts = [...[event]];
  }

  validateRef() {
    if (typeof this.search.ref == 'undefined') {
      this.activeBoton = false;
    } else {
      this.activeBoton = this.search.is_multi && this.ref !== this.search.ref;
    }
  }

  getLinkOdoo(id_odoo) {
    if (id_odoo < 1) {
      this.search.url_odoo = '';
      return;
    }

    this.apiService.getLinkOdoo(id_odoo).subscribe(result => {
      this.search.url_odoo = result;
    });
  }

  add(products: ProductItem[], where: string) {
    if (where == 'products') {
      this.newProducts = [...this.newProducts, ...products];
    } else {
      this.newSuggestions = [...this.newSuggestions, ...products];
    }
  }

  updateReference() {
    if (!this.activeBoton) {
      return this.alertService.error('No existen cambios en la referencia');
    }

    const params: Actions = {
      action: 'updateReference',
      old_ref: this.ref,
      new_ref: this.search.ref,
    };

    this.apiService.actions(params).subscribe(data => {
      if (data.error) {
        return this.alertService.error(data.error);
      }

      this.ref = this.search.ref;
      this.alertService.success('Se actualiza la referencia.');
    });
  }

  desactiveParent() {
    if (typeof this.itemSelected == 'undefined') {
      return this.alertService.error('No hay producto seleccionado');
    }

    const params: Actions = {
      action: 'desactiveParent',
      id: this.itemSelected.id
    };

    this.apiService.actions(params).subscribe(data => {
      if (data.error) {
        return this.alertService.error(data.error);
      }

      this.itemSelected.is_parent = 0;
      this.notAction = false;
      this.updateData();
      this.alertService.success('Se actualiza la referencia.');
    });
  }

  activeParent() {
    if (typeof this.itemSelected == 'undefined') {
      return this.alertService.error('No hay producto seleccionado');
    }

    const params: Actions = {
      action: 'activeParent',
      id: this.itemSelected.id
    };

    this.apiService.actions(params).subscribe(data => {
      if (data.error) {
        return this.alertService.error(data.error);
      }

      this.itemSelected.is_parent = 1;
      this.notAction = true;
      this.updateData();
      this.alertService.success('Se actualiza la referencia.');
    });
  }

  clear() {
    this.itemSelected.ref = '';
    this.notAction = true;
    this.is_multi = false;
    this.search.is_multi = false;
    this.updateProducts();
    this.reload.emit();
  }

  updateData() {
    this.updateProducts();
    this.reload.emit();
  }

  searchProduct(element) {
    this.newSearchProduct.emit(element);
  }
}
