import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-search-brand',
  templateUrl: './search-brand.component.html',
  styleUrls: ['./search-brand.component.scss']
})
export class SearchBrandComponent implements OnInit {
  public brands: string[] = [];
  public brand: string = '';
  public autoControl = new FormControl('');
  public filteredOptions: Observable<string[]>;
  public toHighlight: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  constructor(private apiService: ApiService) {
    this.apiService.getBrands().subscribe(brands => {
      this.brands = brands;
    });
  }

  ngOnInit(): void {
    this.filteredOptions = this.autoControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    if (!value.length) {
      this.brand = '';
      return [];
    }
    const filterValue = value.toLowerCase();
    this.toHighlight = value;
    return this.brands.filter(option => option.toLowerCase().includes(filterValue));
  }

  onSelectBrand(event: MatAutocompleteSelectedEvent) {
    if (event.option) {
      this.brand = event.option.value;
      this.autoControl.setValue(this.brand);
      this.valueChange.emit(this.brand);
    }
  }

  getNameBrand(value: string) {
    if (value) {
      return value[0].toUpperCase() + value.slice(1);
    }

    return '';
  }

  setEmptyBrand() {
    this.brand = '';
    this.autoControl.setValue(this.brand);
    this.valueChange.emit('');
  }
}
