<mat-toolbar>
<span *ngIf="dataSource">{{dataSource.length}} Marcas</span>
<span class="app-toolbar-filler"></span>
<app-options-supplier (selectSupplier)="changeSupplier($event)" [supplier]="''" [discards]="[]" [news]="[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]"></app-options-supplier>
<span class="app-toolbar-filler"></span>
<mat-form-field appearance="standard" class="small-form-field">
  <mat-select [(value)]="state" (selectionChange)="updateTable()">
    <mat-option value="">-</mat-option>
    <mat-option value="1">Activos</mat-option>
    <mat-option value="0">No activos</mat-option>
  </mat-select>
</mat-form-field>
<span class="app-toolbar-filler"></span>
<mat-form-field appearance="standard" class="small-form-field">
  <input matInput placeholder="Filtro" #filter autocomplete="off">
  <button mat-button [disabled]="filter.value.length == 0" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''">
      <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
<span class="app-toolbar-filler"></span>
<mat-slide-toggle color="primary" (change)="changeToogle($event)">Padres</mat-slide-toggle>
<span class="app-toolbar-filler"></span>
<button mat-button (click)="updateTable()" matTooltip="Actualizar"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<table mat-table *ngIf="dataSource" [dataSource]="dataSource" id="table-brands">
  <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
    <th mat-header-cell *matHeaderCellDef>
      <span *ngIf="col !== 'isEdit'" [matTooltip]="matTooltip[col]">
        {{translate[col]}}
      </span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="dataSchema[col]" *ngIf="!element.isEdit">
        <div class="btn-edit" *ngSwitchCase="'isEdit'" >
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="element.active == 1 && element.best != 1" [matTooltip]="'Subir orden de preferencia (' + element.best + ')'" (click)="updateBest(1, element)"><mat-icon>expand_less</mat-icon>Subir</button>
            <button mat-menu-item *ngIf="element.active == 1" [matTooltip]="'Bajar orden de preferencia (' + element.best + ')'" (click)="updateBest(0, element)"><mat-icon>expand_more</mat-icon>Bajar</button>
            <button mat-menu-item *ngIf="element.id != 0" matTooltip="Editar marca" (click)="element.isEdit = !element.isEdit"><mat-icon>edit</mat-icon>Editar</button>
            <button mat-menu-item *ngIf="element.id == 0" matTooltip="Añadir nueva marca" (click)="element.isEdit = !element.isEdit"><mat-icon>add_box</mat-icon>Añadir</button>
            <button mat-menu-item *ngIf="element.active == 1" [matTooltip]="(element.search == 1 ? '' : 'No') + ' se verá en el filtro \'No vinculados\'' | titlecase" (click)="changeSearch(element.search == 1 ? 0 : 1, element)"><mat-icon>{{element.search == 1 ? 'sync' : 'sync_disabled'}}</mat-icon></button>
            <button mat-menu-item *ngIf="element.active == 0" matTooltip="Activar marca" (click)="updateActive(true, element.id); element.active = 1"><mat-icon>link</mat-icon>Activar</button>
            <button mat-menu-item *ngIf="element.active == 1" matTooltip="Desactivar marca" (click)="updateActive(false, element.id); element.active = 0"><mat-icon>link_off</mat-icon>Desactivar</button>
            <button mat-menu-item *ngIf="element.id != 0" matTooltip="Eliminar marca" (click)="deleteBrand(element.id)"><mat-icon>delete</mat-icon>Eliminar</button>
          </mat-menu>
        </div>
        <span *ngSwitchDefault>
          {{element[col]}}
        </span>
      </div>
      <div *ngIf="element.isEdit">
        <div class="btn-edit" *ngIf="col === 'isEdit'; else dataField">
          <button mat-raised-button *ngIf="element.id != 0" matTooltip="Actualizar marca" (click)="element.isEdit = updateBrand(element);"><mat-icon>save</mat-icon></button>
          <button mat-raised-button *ngIf="element.id == 0" matTooltip="Añadir nueva marca" (click)="element.isEdit = addBrand(element);"><mat-icon>save_alt</mat-icon></button>
        </div>
        <ng-template #dataField>
          <mat-form-field>
            <mat-label>{{translate[col]}}</mat-label>
            <input [disabled]="col == 'id'" [type]="dataSchema[col]" matInput [(ngModel)]="element[col]">
          </mat-form-field>
        </ng-template>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class]="getClass(row)"></tr>
</table>
