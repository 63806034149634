<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.total}} registros</span>
  <span class="app-toolbar-filler"></span>
  <app-options-supplier (selectSupplier)="changeSupplier($event)" [supplier]="''" [discards]="[]" [news] = "[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]"></app-options-supplier>
  <span class="app-toolbar-filler"></span>
  <mat-form-field id="date">
    <mat-label>Fecha</mat-label>
    <input matInput [matDatepicker]="picker" autocomplete="off" readonly="true" [(ngModel)]="startDate" (dateChange)="addDate($event)">
    <button *ngIf="date.length" matSuffix mat-icon-button aria-label="Clear" (click)="clearDate()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="updateTable()">
    <mat-icon>refresh</mat-icon>Actualizar
  </button>
</mat-toolbar>
<mat-table class="app-reltable dense" [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="id">
    <mat-header-cell class="index-cell" *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
    <mat-cell class="index-cell" *matCellDef="let item">{{item.id}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="supplier">
    <mat-header-cell class="name-cell" *matHeaderCellDef mat-sort-header>Proveedor</mat-header-cell>
    <mat-cell class="name-cell" *matCellDef="let item">{{item.supplier}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_start">
    <mat-header-cell class="date-cell" *matHeaderCellDef mat-sort-header>Fecha inicio</mat-header-cell>
    <mat-cell class="date-cell" *matCellDef="let item">{{item.date_start | date:'EEEE d MMMM, y, H:mm:ss'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_end">
    <mat-header-cell class="date-cell" *matHeaderCellDef mat-sort-header>Fecha fin</mat-header-cell>
    <mat-cell class="date-cell" *matCellDef="let item">{{item.date_end | date:'EEEE d MMMM, y, H:mm:ss'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Total</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.total}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="download">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Descargado</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.download}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="discontinued">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Descatalogados</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.discontinued}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="news">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Nuevos</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.news}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="update">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Actualizados</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.update}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="errors">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Errores</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.errors}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="menu-cell" *matHeaderCellDef></mat-header-cell>
    <mat-cell class="menu-cell" *matCellDef="let item">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openLink(item.url_cron_without_write)" *ngIf="item.url_cron_without_write.length">
          <mat-icon>update</mat-icon>Ver cambio
        </button>
        <button mat-menu-item (click)="openLink(item.url_cron)" *ngIf="item.url_cron.length">
          <mat-icon>update</mat-icon>Ejecutar
        </button>
        <button mat-menu-item (click)="delete(item)">
          <mat-icon>delete</mat-icon>Eliminar
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" [style.backgroundColor]="getbackgroundColor(row)"></mat-row>
</mat-table>
<mat-paginator *ngIf="dataSource" [length]="dataSource.total" pageSize="10" showFirstLastButtons>
</mat-paginator>
