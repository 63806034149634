import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private snackBar: MatSnackBar) { }

  public show(message: string, action = '×', config: MatSnackBarConfig<any> = { duration: 1000 }) {
    this.snackBar.open(message, action, config);
  }

  public error(message: string, action = '×', config: MatSnackBarConfig<any> = { duration: 4000, panelClass: ['error-snackbar'] }) {
    this.snackBar.open(message, action, config);
  }

  public success(message: string, action = '×', config: MatSnackBarConfig<any> = { duration: 500, panelClass: ['success-snackbar'] }) {
    this.snackBar.open(message, action, config);
  }

  public warn(message: string, action = '×', config: MatSnackBarConfig<any> = { duration: 4000, panelClass: ['warn-snackbar'] }) {
    this.snackBar.open(message, action, config);
  }
}
