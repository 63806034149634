import { Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { OdooProduct } from '../types/odooproducts.model';

@Component({
  selector: 'app-odoo-products',
  templateUrl: './odoo-products.component.html',
  styleUrls: ['./odoo-products.component.scss']
})
export class OdooProductsComponent {
  public dataSource: OdooProduct[];
  public displayedColumns: string[] = ['id', 'supplier', 'type', 'value', 'actions'];
  public dataSchema = {
    'id': 'number',
    'supplier': 'text',
    'type': 'text',
    'value': 'text',
    'actions': ''
  };
  public translate = {
    'id': 'Id',
    'supplier': 'Proveedor',
    'type': 'Filtro',
    'value': 'Valor',
    'actions': ''
  };

  public matTooltip = {
    'id': 'Id',
    'supplier': 'Proveedor',
    'type_filter': 'Tipo de filtro',
    'supplier_reference': 'Referencia proveedor',
    'type': 'Filtro',
    'value': 'Valor',
    'actions': ''
  };

  public active: boolean = true;
  public supplier: string;
  public type: string = 'todos';
  public filter: string = 'todos';
  public types: string[] = [];
  public filters: string[] = [];
  public filedsProduct: string[] = [];

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.getTypes();
    this.updateTable();
  }

  updateTable() {
    if (typeof this.supplier == "undefined") {
      this.supplier = '';
    }

    if (typeof this.type == "undefined") {
      this.type = '';
    }

    if (typeof this.filter == "undefined") {
      this.filter = '';
    }

    this.apiService.getOdooProducts(this.supplier, this.active, this.filter == 'todos' ? '' : this.filter, this.type == 'todos' ? '' : this.type).subscribe(data => {
      if (!data) {
        return this.alertService.error('Error al obtener marcas de la base de datos');
      }

      data.push({
        'id': 0,
        'supplier': 'todos',
        'type': '',
        'value': '',
        'active': true,
        'actions': ''
      });
      this.dataSource = [...data];
    });
  }

  getTypes() {
    this.apiService.getTypesOdooProducts().subscribe(data => {
      data.unshift('todos');
      this.types = data;
    });
  }

  changeToogle(event: MatSlideToggleChange) {
    if (event) {
      this.active = event.checked;
      this.updateTable();
    }
  }

  changeSupplier(supplier: string) {
    if (this.supplier == supplier) {
      return;
    }
    this.supplier = supplier;
    this.updateTable();
  }

  add(element) {
    for (var key in element) {
      if (key != 'actions' && element[key] === "" && (key != 'value' || !(element['type'] == 'desactivar' || element['type'] == 'stock odoo'))) return this.alertService.warn('El campo "' + this.translate[key] + '" está vacío.');
    }

    this.apiService.addOdooProducts(element).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al guardar la nueva excepcion');
      }

      this.updateTable();
    });
  }

  deleteOdooProducts(element) {
    this.apiService.deleteOdooProducts(element.id).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al eliminar la excepcion');
      }

      this.updateTable();
    });
  }

  updateActive(element, value) {
    this.apiService.activeOdooProducts(element.id, value).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al actualizar la excepcion');
      }

      this.updateTable();
    });
  }

  loadFieldsProduct() {
    this.apiService.getFieldsProduct().subscribe(data => {
      this.filedsProduct = data;
    });
  }

  selectFilter(event, element: OdooProduct) {
    element.type = event.value;
    if (element.type == 'mantener') {
      this.loadFieldsProduct();
    } else {
      element.value = '';
    }
  }

  selectFieldProduct(event, element: OdooProduct) {
    if (element.value.indexOf(event.value + ',') == -1) {
      element.value += event.value + ', ';
    }
  }

  updateExceptionValue(element: OdooProduct) {
    this.apiService.updateExceptionValue(element.id, element.value).subscribe(data => {
      if (!data) {
        return this.alertService.error('Error al actualizar la excepcion');
      }

      return this.alertService.success('Excepcion actualizada');
    });
  }

  onSelectProduct(event, element: OdooProduct) {
    element.value = event.internal_ref_supplier;
  }

  onSelectBrand(event, element: OdooProduct) {
    element.value = event;
  }
}
