<mat-toolbar color="secundary" class="app-toolbar">
  <mat-form-field class="filter-input" style="padding-top: 20px;">
    <input type="text" matInput [(ngModel)]="filter" placeholder="Buscar producto" autocomplete="off">
    <button *ngIf="filter.length" matSuffix mat-icon-button aria-label="Clear" (click)="filter=''; checkAllProducts()"><mat-icon>close</mat-icon></button>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <app-search-brand (valueChange)="onSelectBrand($event)" class="form-auto"></app-search-brand>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="checkAllProducts()"><mat-icon>{{!filter.length && !brand.length ? 'refresh' : 'search'}}</mat-icon>{{!filter.length && !brand.length ? 'Recargar' : 'Buscar'}}</button>
  <span class="app-toolbar-filler"></span>
  <mat-slide-toggle style="display: unset;" [(ngModel)]="news_checks" color="primary" (change)="checkAllProducts()">{{news_checks ? 'Nuevos' : 'Todos'}}</mat-slide-toggle>
  <span class="app-toolbar-filler"></span>
  <div>
    <mat-slide-toggle style="display: unset;" [(ngModel)]="descatalogados" color="primary" (change)="checkAllProducts()">{{descatalogados ? 'Descatalogados' : 'No descatalogados'}}</mat-slide-toggle>
    <mat-slide-toggle style="display: unset;" [(ngModel)]="stock" color="primary" (change)="checkAllProducts()">{{stock ? 'Con stock' : 'Sin stock'}}</mat-slide-toggle>
  </div>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" opened class="drawer">
      <div class="num-products" *ngIf="items && items.length">Productos: {{items.length}}</div>
      <div class="num-products" *ngIf="!items">Productos: 0</div>
      <div class="list">
          <cdk-virtual-scroll-viewport itemSize="51" class="viewport">
              <div mat-list-item *cdkVirtualFor="let item of items" class="item-viewport" [class.active]="item === activeElement" [style.background-color]="item.color">
                  <div mat-line class="title-cdk" (click)="setProduct(item)">
                      <div *ngIf="item">
                          {{item.reference}} {{ (item.name.length > 6) ? (item.name | slice:0:60) + '..' : (item.name) }}
                      </div>
                      <div *ngIf="!item">
                          Loading...
                      </div>
                  </div>
              </div>
          </cdk-virtual-scroll-viewport>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <div *ngIf="activeElement && search">
          <div class="left-element">
            <div mat-card-avatar><img [src]="search.image" [alt]="search.ref" /></div>

            <div class="form">
              <form>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Referencia</mat-label>
                  <input matInput placeholder="Referencia" [(ngModel)]="search.ref" [ngModelOptions]="{standalone: true}" [disabled]="true" autocomplete="off">
                </mat-form-field>
              </form>
            </div>

            <div *ngIf="search.url_product.length"><a target="_blank" [href]="search.url_product">Prestashop</a></div>
            <div *ngIf="activeElement.color != 'rgb(255, 235, 235)'" class="discards">
              <button mat-raised-button (click)="discardReference()">Descartar</button>
            </div>
          </div>

          <div class="right-element">
            <app-table [url_image] = "''" [products] = "search.products" [newProducts] = "newProducts" [deleted] = "newSuggestions" [type]="'products'" [notAction]="notAction" [isMulti]="search.is_multi" [ref]="search.ref" [changedRef]="activeBoton" (addProducts)="add($event, 'suggestions')" (unLink)="clear()" (link)="updateData()" style="margin: 10px;"></app-table>
            <app-table [url_image] = "''" [products] = "search.suggestions" [newProducts] = "newSuggestions" [deleted] = "newProducts" [type]="'suggestions'" [notAction]="notAction" [isMulti]="false" [ref]="search.ref" (addProducts)="add($event, 'products')" style="margin: 10px;"></app-table>
          </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
