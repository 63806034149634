import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from '../services/alert.service';
import { ProductItem } from '../types/product-item-model';

export class MyDataSource extends DataSource<ProductItem | undefined> {
    // private _pageSize = Math.ceil((window.screen.height || 1000) / 50);
    private _pageSize = Math.ceil((window.screen.height || 1000) / 60);
    private _cachedData = Array.from<ProductItem>({ length: 1 });
    private _fetchedPages = new Set<number>();
    private _dataStream = new BehaviorSubject<(ProductItem | undefined)[]>(this._cachedData);
    private _subscription = new Subscription();
    public _startPage: number;
    public _endPage: number;
    public length: number;
    private type: string;
    private filter: string;
    private brand: string;
    private supplier: string;
    private load: boolean;
    private descatalogados: boolean;
    private stock: boolean;
    private request: any = false;

    constructor(private apiService: ApiService, private alertService: AlertService) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<(ProductItem | undefined)[]> {
        this._subscription.add(collectionViewer.viewChange.pipe(debounceTime(300)).subscribe(range => {
            this._startPage = this._getPageForIndex(range.start);
            this._endPage = this._getPageForIndex(range.end - 1);
            for (let page = this._startPage; page <= this._endPage; page++) {
                this._fetchPage(page, this.type, this.filter, this.brand, this.supplier, this.load, this.descatalogados, this.stock);
            }
        }));
        return this._dataStream;
    }

    disconnect(): void {
        this._subscription.unsubscribe();
    }

    setFilters(type: string, filter: string, brand: string, supplier: string, load: boolean, descatalogados: boolean, stock: boolean) {
        this._fetchPage(this._startPage, type, filter, brand, supplier, load, descatalogados, stock);
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    private _fetchPage(page: number, type?: string, filter?: string, brand?: string, supplier?: string, load?: boolean, descatalogados?: boolean, stock?: boolean) {
        if (load) {
            if (this.type == type && this.filter == filter && this.brand == brand && this.supplier == supplier && this.descatalogados == descatalogados && this.stock == stock) {
                if (this._fetchedPages.has(page)) {
                    return;
                }
            }
        }

        this.type = type;
        this.load = load;
        this.filter = filter;
        this.brand = brand;
        this.supplier = supplier;
        this.descatalogados = descatalogados;
        this.stock = stock;
        this._fetchedPages.add(page);

        const offset = page * this._pageSize;
        const limit = offset + this._pageSize;

        if ( this.request ) {
          this.request.unsubscribe();
       }

        const request = this.apiService.loadDataSource(offset, limit, type, filter, 0, brand, supplier, descatalogados, stock);

        this.request = request.subscribe(data => {
            if (data['error']) {
                return this.alertService.error(data['error']);
            }

            if (this._cachedData.length !== data['total']) {
                this.length = data['total'];
                this._cachedData = Array.from({ length: data['total'] });
                this._fetchedPages = new Set<number>([page]);
            }

            this._cachedData.splice(offset, data['result'].length, ...data['result']);
            this._dataStream.next(this._cachedData);
        });

    }
}
