<div *ngIf="!products.length && !error.length" style="padding: 10px; font-size:larger">
  No se encuentran datos con el filtro '<strong>{{search}}</strong>'.
</div>
<div *ngIf="error.length" style="padding: 10px; font-size:larger">
  <strong>{{error}}</strong>
</div>
<div *ngIf="is_multi && products.length" style="padding: 10px;">
  <h3>Es un producto multi proveedor</h3>
</div>
<div *ngIf="!is_multi && products.length" style="padding: 10px;">
  <h3>Es un producto simple</h3>
</div>
<div>
  <app-table [url_image] = "''" [products] = "products" [newProducts] = "[]" [deleted] = "[]" [type]="''" [notAction]="true" [ref]="ref" [changedRef]="false" style="margin: 10px;"></app-table>
</div>
