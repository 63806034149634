import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { FileTarifa } from 'src/app/types/supplier-tarifa.model';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent {
  myDemoUploadForm = new FormGroup({
    uploadedFileSource: new FormControl('')
  });

  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<FileComponent>, @Inject(MAT_DIALOG_DATA) public data: FileTarifa) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNew() {
    const uploadFormData = new FormData();
    uploadFormData.append('uploadedFile', this.myDemoUploadForm.get('uploadedFileSource')?.value);

    this.apiService.addFileTarifa(uploadFormData, this.data.id_supplier, this.data.prefix, this.data.update_ean, this.data.update_price, this.data.update_whosale_price, this.data.update_category, this.data.dto).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (!r['result']) {
        return this.alertService.error("No se pudo crear.");
      }
    })
  }

  update() {
    this.apiService.updateFileTarifa(this.data.id, this.data.prefix, this.getBool(this.data.update_ean), this.getBool(this.data.update_price), this.getBool(this.data.update_whosale_price), this.getBool(this.data.update_category), this.data.dto).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (!r['result']) {
        return this.alertService.error("No se pudo actualizar.");
      }

      return this.alertService.success("Se actualiza.");
    })
  }

  selectFile(event) {
    if (event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];
      this.myDemoUploadForm.patchValue({
        uploadedFileSource: uploadedFile
      });
    }
  }

  getBool(value) {
    if (typeof value == 'string') {
      return value === '1';
    }

    return value;
  }
}
