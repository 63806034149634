import { Component } from '@angular/core';

@Component({
  selector: 'app-logs-list',
  templateUrl: './rules-list.component.html',
  styleUrls: ['./rules-list.component.scss']
})
export class RulesListComponent {
  constructor() { }
}
