import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { SupplierTarifa } from 'src/app/types/supplier-tarifa.model';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent {
  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<SupplierComponent>, @Inject(MAT_DIALOG_DATA) public data: SupplierTarifa) {
    this.apiService.searchOdoo("" + this.data.categ_id, 'product.category').subscribe(s => {
      this.data.categ = s.result[0];
    })

    this.apiService.searchOdoo("" + this.data.brand_id, 'product.brand').subscribe(s => {
      this.data.brand = s.result[0];
    })

    this.apiService.searchOdoo("" + this.data.supplier_id, 'res.partner').subscribe(s => {
      this.data.supplier = s.result[0];
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateSearch(value: number, field: string) {
    this.data[field] = value;
  }

  addNew() {
    this.apiService.addSupplierTarifa(this.data).subscribe(r => {

      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      console.log(r);

      if (!r['result']) {
        return this.alertService.error("No se pudo crear.");
      }
    })
  }

  update() {
    this.apiService.updateSupplierTarifa(this.data).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (!r['result']) {
        return this.alertService.error("No se pudo actualizar.");
      }

      return this.alertService.success("Se actualiza.");
    })
  }
  
}
