<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.total}} productos</span>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="updateTable()">
        <mat-icon>refresh</mat-icon>Actualizar
  </button>
  <span class="app-toolbar-filler"></span>
  <mat-form-field class="calendar">
    <input matInput [matDatepicker]="picker" [formControl]="date" placeholder="Fecha" (dateChange)="updateTable()">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matSuffix (click)="clearStartDate()" *ngIf="date.value !== null">
      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="filter" class="toolbar-icon">filter_list</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <input matInput placeholder="Filtro" #filter>
    <button mat-button [disabled]="filter.value.length == 0" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>
</mat-toolbar>
<mat-table class="app-reltable dense" [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="id">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Referencia</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.ref_supplier}} ({{item.internal_ref_supplier}}) {{item.supplier | titlecase}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="wholesale_price">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Precio de compra</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.wholesale_price | currency:'EUR':'symbol'}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="price">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Precio de venta</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.price | currency:'EUR':'symbol'}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="stock">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Stock</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.stock}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="date">
    <mat-header-cell class="id-cell" *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
    <mat-cell class="id-cell" *matCellDef="let item">{{item.date | date:'EEEE d MMMM, y, h:mm:ss'}}</mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"></mat-row>
</mat-table>

<mat-paginator *ngIf="dataSource" [length]="dataSource.total" pageSize="10" showFirstLastButtons></mat-paginator>
