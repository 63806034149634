import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { Brand } from '../types/brand.model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})

export class BrandsComponent implements AfterViewInit {
  public dataSource: Brand[];
  public displayedColumns: string[] = ['id', 'supplier', 'brand', 'transform_brand', 'isEdit'];
  public dataSchema = {
    'id': 'number',
    'supplier': 'text',
    'brand': 'text',
    'transform_brand': 'text',
    'isEdit': 'isEdit'
  };
  public translate = {
    'id': 'Id',
    'supplier': 'Proveedor',
    'brand': 'Marca',
    'transform_brand': 'Marca transformada',
    'isEdit': 'isEdit'
  };

  public matTooltip = {
    'id': '',
    'supplier': 'Proveedor',
    'brand': 'Marca del proveedor',
    'transform_brand': 'Marca con la que se trabajara',
    'isEdit': ''
  };

  public suppliers: string[];
  public supplier: string;
  public parents: boolean = false;
  public state: string = '1';
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.updateTable();
    this.getSuppliersBrands();
  }

  updateTable() {
    this.getBrands();
  }

  changeToogle(event: MatSlideToggleChange) {
    if (event) {
      this.parents = event.checked;
      this.updateTable();
    }
  }

  ngAfterViewInit() {
    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.updateTable();
      });
  }

  getBrands() {
    if (typeof this.supplier == "undefined") {
      this.supplier = '';
    }

    if (typeof this.state == "undefined") {
      this.state = '';
    }

    if (typeof this.filter == "undefined") {
      this.filter = {
        nativeElement: {
          value: ''
        }
      };
    }

    this.apiService.getBrandsByFilter(this.supplier, this.state, this.filter.nativeElement.value, this.parents).subscribe(data => {
      if (!data) {
        return this.alertService.error('Error al obtener marcas de la base de datos');
      }

      data.push({
        'id': 0,
        'supplier': '',
        'brand': '',
        'transform_brand': '',
        'best': 0,
        'search': 0
      });
      this.dataSource = [...data];
    });
  }

  getSuppliersBrands() {
    this.apiService.getSuppliersBrands().subscribe(data => {
      if (!data) {
        return this.alertService.error('Error al obtener proveedores de la base de datos');
      }
      this.suppliers = [...data];
    });
  }

  updateActive(active: boolean, id: number) {
    const params = {
      action: 'updateActive',
      id: `${id}`,
      active: active
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido actualizar la marca.');
      }

      this.getBrands();
    });
  }

  changeSearch(search: number, brand: Brand) {
    const params = {
      action: 'changeSearch',
      id: `${brand.id}`,
      search: search
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido actualizar la marca.');
      }

      brand.search = search;
    });
  }

  updateBest(upward: number, brand: Brand) {
    if (brand.active == false) {
      return this.alertService.error('La marca no está activa.');
    }

    const params = {
      action: 'changeBest',
      id: `${brand.id}`,
      upward: `${upward}`
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('No se ha podido actualizar la marca.');
      }

      this.getBrands();
    });
  }

  isToActiveOrDesactive(brand: Brand) {
    if (typeof brand.active == 'undefined') {
      return 0;
    }

    if (brand.active == false) {
      return false;
    }

    return true;
  }

  getClass(brand: Brand) {
    let classes = '';

    if (brand.search == 1) {
      classes += 'search ';
    }

    if (brand.active && brand.best == 1) {
      return classes + 'best-brand';
    }

    if (brand.active == true) {
      classes += 'active-brand';
    }

    return classes;
  }

  updateBrand(brand: Brand) {
    var errors = this.validateIfEmpty(brand);

    if (errors.length) {
      this.alertService.error(errors[0]);
      return true;
    }

    const params = {
      action: 'updateBrand',
      id: `${brand.id}`,
      supplier: brand.supplier,
      brand: brand.brand,
      transform_brand: brand.transform_brand
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (data !== true) {
        this.alertService.error('No se ha podido actualizar la marca ' + brand.brand);
      } else {
        this.alertService.success('Se actualiza correctamente la marca ' + brand.brand);
      }

      return false;
    });
  }

  deleteBrand(id: number) {
    if (!confirm("¿Seguro que quieres eliminar la marca?")) {
      return;
    }

    if (id < 1) {
      return this.alertService.error('La marca no es correcta');
    }

    const params = {
      action: 'deleteBrand',
      id: `${id}`
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (!data) {
        return this.alertService.error('No se ha podido añadir la nueva marca');
      }

      this.dataSource.forEach((brand, index) => {
        if (brand.id == id) {
          this.dataSource.splice(index, 1);
          this.dataSource = [...this.dataSource];
        }
      });
    });
  }

  validateIfEmpty(brand) {
    var errors = [];
    Object.keys(brand).map((key) => {
      if (key == 'id' || key == 'isEdit' || key == 'validated' || key == 'best' || key == 'active') {

      } else {
        if (!brand[key] || !brand[key].trim()) {
          errors.push('El campo "' + this.translate[key] + '" está vacio');
        }
      }
    });

    return errors;
  }

  addBrand(brand: Brand) {
    var errors = this.validateIfEmpty(brand);

    if (errors.length == 3) {
      return false;
    }

    if (errors.length) {
      this.alertService.error(errors[0]);
      return true;
    }

    const params = {
      action: 'addBrand',
      supplier: brand.supplier,
      brand: brand.brand,
      transform_brand: brand.transform_brand
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (data.error) {
        this.alertService.error(data.error);
        return true;
      }

      if (!data) {
        this.alertService.error('No se ha podido añadir la nueva marca');
        return true;
      }

      this.updateTable();
    });
  }

  addUpdateBrand(params) {
    this.apiService.actionsBrand(params).subscribe(data => {
      if (data.error) {
        this.alertService.error(data.error);
        return false;
      }

      if (!data) {
        this.alertService.error('No se ha podido añadir la nueva marca');
        return false;
      }

      return false;
    });
  }

  changeSupplier(supplier: string) {
    if (this.supplier == supplier) {
      return;
    }
    this.supplier = supplier;
    this.updateTable();
  }
}
