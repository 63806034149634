import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { LoadData } from '../types/load-data-model';
import { Price } from "../types/price-model";

export class PricesDataSource implements DataSource<Price> {
  private dataSubject = new BehaviorSubject<Price[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public total = 0;

  constructor(private api: ApiService, private alertService: AlertService) { }

  connect(collectionViewer: CollectionViewer): Observable<Price[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  setData(data) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.value;
  }

  loadData(pageIndex = 0, pageSize = 50, filter = '', date = '', orderBy = '', orderWay = '') {
    this.loadingSubject.next(true);

    this.api.getPrices(pageIndex * pageSize, ((pageIndex * pageSize) + pageSize), filter, date, orderBy, orderWay).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((data: LoadData<Price>) => {
        if (data['error']) {
          return this.alertService.error(data['error']);
        }
        this.total = data.total
        this.dataSubject.next(data.result)
      });
  }
}
