<div *ngIf="dataSource.data.length">
  <div *ngIf="type == 'products'">
    Productos relacionados
  </div>

  <div *ngIf="type == 'suggestions'">
    Sugerencias
  </div>

  <table mat-table [dataSource]="dataSource" class="table-products">
    <!-- Selector Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef> <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [disabled]="notAction"></mat-checkbox> </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element.id) : null" [checked]="selection.isSelected(element.id)" [disabled]="isDisabled(element)"></mat-checkbox></td>
    </ng-container>

    <!-- Ref supplier Column -->
    <ng-container matColumnDef="ref_supplier">
      <th mat-header-cell *matHeaderCellDef>Ref proveedor</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.date_add | date: 'H:mm:ss d MMM y'">{{ element.ref_supplier }}</td>
    </ng-container>

    <!-- Internal ref supplier Column -->
    <ng-container matColumnDef="internal_ref_supplier">
      <th mat-header-cell *matHeaderCellDef>Ref internal proveedor</th>
      <td mat-cell *matCellDef="let element">
        {{ element.internal_ref_supplier }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.date_upd | date: 'H:mm:ss d MMM y'">{{ element.name }}</td>
    </ng-container>

    <!-- Supplier Column -->
    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef>Proveedor</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex;">
          {{ element.supplier }}
          <mat-icon *ngIf="element.category == 104" matTooltip="Descatalogado">warning</mat-icon>
          <mat-icon *ngIf="element.category == 123456789" matTooltip="Producto en promoción">monetization_on</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef>Marca</th>
      <td mat-cell *matCellDef="let element">{{ element.brand }}</td>
    </ng-container>

    <!-- Id product Column -->
    <ng-container matColumnDef="id_product">
      <th mat-header-cell *matHeaderCellDef>ID ps</th>
      <td mat-cell *matCellDef="let element" [satPopoverAnchor]="popover" (click)="popover.open()">
        {{ element.id_product }}
        <sat-popover #popover hasBackdrop xAlign="start" yAlign="start" (closed)="save(element, $event, 'id_ps')">
          <app-inline-edit [value]="element.id_product" [title]="'Id Prestashop'"></app-inline-edit>
        </sat-popover>
      </td>
    </ng-container>

    <!-- Id odoo Column -->
    <ng-container matColumnDef="id_odoo">
      <th mat-header-cell *matHeaderCellDef>ID odoo</th>
      <td mat-cell *matCellDef="let element" [satPopoverAnchor]="popover" (click)="popover.open()">
        {{ element.id_odoo }}
        <sat-popover #popover hasBackdrop xAlign="start" yAlign="start" (closed)="save(element, $event, 'id_odoo')">
          <app-inline-edit [value]="element.id_odoo" [title]="'Id Odoo'"></app-inline-edit>
        </sat-popover>
      </td>
    </ng-container>

    <!-- Stock Column -->
    <ng-container matColumnDef="stock">
      <th mat-header-cell *matHeaderCellDef>Stock</th>
      <td mat-cell *matCellDef="let element">{{ element.stock }}</td>
    </ng-container>

    <!-- Wholesale price Column -->
    <ng-container matColumnDef="wholesale_price">
      <th mat-header-cell *matHeaderCellDef>Precio compra</th>
      <td mat-cell *matCellDef="let element">{{ element.wholesale_price | currency:'EUR':'symbol'}}</td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>PVP</th>
      <td mat-cell *matCellDef="let element">{{ element.price | currency:'EUR':'symbol'}}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>-</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="isMulti && element.ref != '' && element.is_parent == false" matTooltip="Cambiar a producto padre" (click)="updateParent(element)">
            <mat-icon>verified_user</mat-icon>
            <span>Actualizar padre</span>
          </button>
          <button mat-menu-item *ngIf="type == 'suggestions' && element.ref == '' && element.is_parent == true" matTooltip="Desactivar producto padre" (click)="desactiveParent(element)">
            <mat-icon>touch_app</mat-icon>
            <span>Desactivar</span>
          </button>
          <button mat-menu-item *ngIf="dataSource.data.length > 2 && element.ref == ''" (click)="actionProducts('add', false, true, element)" matTooltip="Añadir nuevo producto">
            <mat-icon>add_box</mat-icon>
            <span>Añadir</span>
          </button>
          <button mat-menu-item *ngIf="dataSource.data.length > 2 && type != 'suggestions' && element.is_parent == false" matTooltip="Eliminar producto" (click)="actionProducts('delete', false, true, element)">
            <mat-icon>delete_forever</mat-icon>
            <span>Eliminar</span>
          </button>
          <button mat-menu-item [disabled]="!element.url_cron_without_write || !element.url_cron_without_write.length" matTooltip="Ver datos que se actualizarán en prestashop y odoo" (click)="openUrlCron(element.url_cron_without_write)">
            <mat-icon>track_changes</mat-icon>
            <span>Ver cambios</span>
          </button>
          <button mat-menu-item [disabled]="!element.url_cron || !element.url_cron.length" matTooltip="Actualizar producto en prestashop y odoo" (click)="openUrlCron(element.url_cron)">
            <mat-icon>update</mat-icon>
            <span>Actualizar</span>
          </button>
          <button mat-menu-item [disabled]="!element.url_supplier || !element.url_supplier.length" matTooltip="Ver producto en el proveedor" (click)="openUrlCron(element.url_supplier)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>Proveedor</span>
          </button>
          <button mat-menu-item *ngIf="type == 'suggestions' && element.validated == true" matTooltip="Abrir producto" (click)="openProduct(element)">
            <mat-icon>open_in_browser</mat-icon>
            <span>Abrir</span>
          </button>
          <button mat-menu-item *ngIf="type != 'suggestions' && element.ref == '' && element.id_product == 0 && (element.supplier == 'bydemes'|| element.supplier == 'visiotech')" matTooltip="Crear el producto en prestshop" (click)="createNewProduct(element)">
            <mat-icon>fiber_new</mat-icon>
            <span>Prestashop</span>
          </button>
          <button mat-menu-item *ngIf="type != 'suggestions' && element.ref == '' && element.id_odoo == 0 && element.id_product > 0" matTooltip="Crear el producto en odoo" (click)="createProductInOdoo(element)">
            <mat-icon>fiber_new</mat-icon>
            <span>Odoo</span>
          </button>
          <button mat-menu-item *ngIf="type != 'suggestions' && element.id_product > 0 && url_image.length" matTooltip="Actualizar imagenes del producto" (click)="updateImage(element)">
            <mat-icon>add_a_photo</mat-icon>
            <span>Añadir</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class]="isParent(row)" matTooltipPosition='left' [matTooltip]="type == 'suggestions' && row.validated == true ? 'Producto ya vinculado.' : ''"></tr>
  </table>

  <div *ngIf="type == 'products'">
    <button mat-raised-button *ngIf="isMulti" (click)="actionProducts('delete')">Eliminar</button>
    <button mat-raised-button color="primary" *ngIf="changedRef" (click)="updateReference()">Actualizar referencia</button>
    <button mat-raised-button color="warn" *ngIf="isMulti" (click)="breakBond()">Desvincular</button>
    <button mat-raised-button color="warn" *ngIf="dataSource.data.length > 1 && !isMulti && !desactiveAdd" (click)="actionProducts('add', true)">Vincular</button>
    <button mat-raised-button *ngIf="!openGraph" (click)="openGraph = !openGraph">Ver gráfica de precios</button>
  </div>

  <div *ngIf="type == 'suggestions'">
    <button mat-raised-button (click)="actionProducts('add')">Añadir</button>
  </div>

  <div *ngIf="dataSource.data && openGraph">
    <app-graph [data]="dataSource.data" [open]="openGraph"></app-graph>
  </div>
</div>
