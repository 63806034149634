<div style="width:100%;" class="content-graph" [hidden]="open !== true">
	<div>
    <mat-form-field class="calendar">
      <input matInput [matDatepicker]="pickerStart" [formControl]="dateStart" placeholder="Fecha de inicio" [max]="dateEnd.value">
      <mat-datepicker #pickerStart></mat-datepicker>
      <mat-datepicker-toggle matSuffix (click)="clearStartDate(dateStart)" *ngIf="dateStart.value !== null">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
    </mat-form-field>

    <mat-form-field class="calendar">
      <input matInput [matDatepicker]="pickerEnd" [formControl]="dateEnd" placeholder="Fecha de fin" [min]="dateStart.value">
      <mat-datepicker #pickerEnd></mat-datepicker>
      <mat-datepicker-toggle matSuffix (click)="clearStartDate(dateEnd)" *ngIf="dateEnd.value !== null">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    </mat-form-field>

    <div class="search">
      <button mat-raised-button (click)="load()">Buscar</button>
    </div>
  <div>

  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingData === true"></mat-progress-bar>
	<canvas id="wholesalePrice" width="750" height="275" #wholesalePrice [hidden]="isLoadingData !== true"></canvas>
	<canvas id="price" width="750" height="275" #price [hidden]="isLoadingData !== true"></canvas>
	<canvas id="stock" width="750" height="275" #stock [hidden]="isLoadingData !== true"></canvas>
</div>
