import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { CheckProduct, ProductItem, SearchProducts } from 'src/app/types/product-item-model';
import { LoadData, LoadDataLogs } from 'src/app/types/load-data-model';
import { Brand } from '../types/brand.model';
import { ShowPrices } from '../types/show-prices.model';
import { Supplier } from '../types/supplier.model';
import { SupplierBrand } from '../types/supplier-brand.model';
import { Observable } from 'rxjs';
import { Exceptions } from '../types/exceptions.model';
import { ControlLoadData } from '../types/control.model';
import { Graphs } from '../types/line-graph.model';
import { Price } from '../types/price-model';
import { OdooProduct } from '../types/odooproducts.model';
import { ControlEmployee, Employee } from '../types/control-employee.model';
import { FileTarifa, HeaderTarifa, SupplierTarifa } from '../types/supplier-tarifa.model';
import { SearchOdooAutocomplete } from '../types/search-odoo.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private typesExceptions: Observable<string[]>;
  private typesOdooProducts: Observable<string[]>;
  private filtersExceptions: Observable<string[]>;
  private fieldsProduct: Observable<string[]>;
  private employees: Observable<Employee[]>;
  private API_URL = window.location.pathname.replace('/manager/', '/api.php');

  constructor(private http: HttpClient, private alertService: AlertService) {
    if (!environment.production) { // isDevMode()
      this.API_URL = window.location.hostname.includes('localhost') ? 'http://localhost/prestashop' : 'https://dm.orbitadigital.es';
      this.API_URL += '/import/multi-supplier/api.php';
    }

    console.log(this.API_URL);
  }

  public validateError(error, message) {
    console.log(error);

    if (typeof error.error != "undefined") {
      this.alertService.error(error.error.text);
    }

    if (typeof error.status == "undefined" || error.status == 200) {
      return true;
    }

    if (error.status == 0) {
      error.status = '';
    }

    this.alertService.error("ERROR " + error.status + message);
  }

  public loadDataSource(offset: number, limit: number, type: string, filter: string, newProducts: number, brand: string, supplier: string, descatalogados: boolean, stock: any) {
    return this.http.get<LoadData<ProductItem>>(this.API_URL, {
      params: {
        action: 'loadDataSource',
        offset: `${offset}`,
        limit: `${limit}`,
        type: encodeURIComponent(type),
        filter: encodeURIComponent(filter),
        brand: encodeURIComponent(brand),
        supplier: encodeURIComponent(supplier),
        newProducts: `${newProducts}`,
        descatalogados: `${descatalogados}`,
        stock: `${stock}`,
      },
      responseType: 'json'
    });
  }

  public loadDataSourceByDir(dir: string = 'src/Rules', offset: number, limit: number, filter: string = '', reference: string = '') {
    return this.http.get<LoadDataLogs>(this.API_URL, {
      params: {
        action: 'loadDataSourceByDir',
        dir: encodeURIComponent(dir),
        offset: `${offset}`,
        limit: `${limit}`,
        filter,
        reference: encodeURIComponent(reference),
      },
      responseType: 'json'
    });
  }

  public getDocLogByFile(file_name: string) {
    return this.http.get(this.API_URL, {
      params: {
        action: 'getDocLogByFile',
        file_name: encodeURIComponent(file_name)
      },
      responseType: 'json'
    });
  }

  public getBrands() {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'getBrands'
      },
      responseType: 'json'
    });
  }

  public getDocRuleByFile(file_name: string) {
    return this.http.get(this.API_URL, {
      params: {
        action: 'getDocRuleByFile',
        file_name: encodeURIComponent(file_name)
      },
      responseType: 'text'
    });
  }

  public executeRule(file_name: string, allData: string) {
    return this.http.get(this.API_URL, {
      params: {
        action: 'executeRule',
        file_name: encodeURIComponent(file_name),
        allData: encodeURIComponent(allData)
      },
      responseType: 'json'
    });
  }

  public updateRule(rule: string, file: string) {
    return this.http.post<string[]>(this.API_URL, rule, {
      params: {
        action: 'updateRule',
        file: encodeURIComponent(file)
      },
      responseType: 'json'
    });
  }

  public loadDataByProduct(id: number, ref: string, brand: string) {
    return this.http.get<SearchProducts>(this.API_URL, {
      params: {
        action: 'loadDataByProduct',
        id: `${id}`,
        ref: encodeURIComponent(ref),
        brand: encodeURIComponent(brand)
      },
      responseType: 'json'
    });
  }

  public actions(params) {
    return this.http.get<SearchProducts>(this.API_URL, {
      params,
      responseType: 'json'
    });
  }

  public actionsBrand(params) {
    return this.http.get<any>(this.API_URL, {
      params,
      responseType: 'json'
    });
  }

  public getBrandsByFilter(supplier: string = '', active: string = '', filter: string = '', parents: boolean = false) {
    return this.http.get<Brand[]>(this.API_URL, {
      params: {
        action: 'getBrandsByFilter',
        supplier: encodeURIComponent(supplier),
        active: `${active}`,
        filter: encodeURIComponent(filter),
        parents: `${parents}`,
      },
      responseType: 'json'
    });
  }

  public getSuppliersBrands() {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'getSuppliersBrands'
      },
      responseType: 'json'
    });
  }

  public breakBond(ref: string) {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'breakBond',
        ref: encodeURIComponent(ref)
      },
      responseType: 'json'
    });
  }

  public addToParents(filter: string, type: string, brand: string, supplier: string) {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'addToParents',
        filter: encodeURIComponent(filter),
        type: encodeURIComponent(type),
        brand: encodeURIComponent(brand),
        supplier: encodeURIComponent(supplier)
      },
      responseType: 'json'
    });
  }

  public showPrices(search: string) {
    return this.http.get<ShowPrices>(this.API_URL, {
      params: {
        action: 'showPrices',
        search: encodeURIComponent(search)
      },
      responseType: 'json'
    });
  }

  public getSuppliers() {
    return this.http.get<Supplier[]>(this.API_URL, {
      params: {
        action: 'getSuppliers'
      },
      responseType: 'json'
    });
  }

  public getParents(offset: number, limit: number, filter: string, brand: string, orderBy: string, orderWay: string) {
    return this.http.get<LoadData<ProductItem>>(this.API_URL, {
      params: {
        action: 'getParents',
        offset: `${offset}`,
        limit: `${limit}`,
        filter: encodeURIComponent(filter),
        brand: encodeURIComponent(brand),
        orderBy: encodeURIComponent(orderBy),
        orderWay: encodeURIComponent(orderWay)
      },
      responseType: 'json'
    });
  }

  public getPrices(offset: number, limit: number, filter: string, date: string, orderBy: string, orderWay: string) {
    return this.http.get<LoadData<Price>>(this.API_URL, {
      params: {
        action: 'getPrices',
        offset: `${offset}`,
        limit: `${limit}`,
        filter: encodeURIComponent(filter),
        date: encodeURIComponent(date),
        orderBy: encodeURIComponent(orderBy),
        orderWay: encodeURIComponent(orderWay)
      },
      responseType: 'json'
    });
  }

  public saveIdsParent(ref: string, id_ps: number, id_odoo: number) {
    return this.http.get<string>(this.API_URL, {
      params: {
        action: 'saveIdsParent',
        ref: encodeURIComponent(ref),
        id_ps: `${id_ps}`,
        id_odoo: `${id_odoo}`,
      },
      responseType: 'json'
    });
  }

  public getBrandsSuppliers(supplier: string) {
    return this.http.get<SupplierBrand[]>(this.API_URL, {
      params: {
        action: 'getBrandsSuppliers',
        supplier
      },
      responseType: 'json'
    });
  }

  public actionBrandSupplier(supplier: string, brand: string, action: string) {
    return this.http.get<any[]>(this.API_URL, {
      params: {
        action: 'actionBrandSupplier',
        supplier,
        brand,
        a: action
      },
      responseType: 'json'
    });
  }

  public changeDirectlyIds(id: number, id_product: number, id_odoo: number, type: string) {
    return this.http.get<boolean | []>(this.API_URL, {
      params: {
        action: 'changeDirectlyIds',
        id: `${id}`,
        id_product: `${id_product}`,
        id_odoo: `${id_odoo}`,
        type
      },
      responseType: 'json'
    });
  }

  public checkAllProducts(filter: string, brand: string, descatalogados: boolean, stock: boolean, news_checks: boolean) {
    return this.http.get<CheckProduct[]>(this.API_URL, {
      params: {
        action: 'checkAllProducts',
        filter: encodeURIComponent(filter),
        brand: encodeURIComponent(brand),
        descatalogados: `${descatalogados}`,
        stock: `${stock}`,
        news_checks: `${news_checks}`
      },
      responseType: 'json'
    });
  }

  public discardReference(reference: string, ids: string) {
    return this.http.get<CheckProduct[]>(this.API_URL, {
      params: {
        action: 'discardReference',
        reference: encodeURIComponent(reference),
        ids: encodeURIComponent(ids),
      },
      responseType: 'json'
    });
  }

  public searchDataToValidate(ids: string) {
    return this.http.get<SearchProducts>(this.API_URL, {
      params: {
        action: 'searchDataToValidate',
        ids,
      },
      responseType: 'json'
    });
  }

  public getExceptions() {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'getExceptions',
      },
      responseType: 'json'
    });
  }

  public getExceptionFile(supplier: string) {
    return this.http.get<string>(this.API_URL, {
      params: {
        action: 'getExceptionFile',
        supplier: encodeURIComponent(supplier),
      },
      responseType: 'json'
    });
  }

  public updateException(doc: string, file: string) {
    return this.http.post<string[]>(this.API_URL, doc, {
      params: {
        action: 'updateException',
        file: encodeURIComponent(file)
      },
      responseType: 'json'
    });
  }

  public getTypesExceptions() {
    if (typeof this.typesExceptions === 'undefined') {
      this.typesExceptions = this.http.get<string[]>(this.API_URL, {
        params: {
          action: 'getTypesExceptions'
        },
        responseType: 'json'
      });
    }

    return this.typesExceptions;
  }

  public getFiltersExceptions() {
    if (typeof this.filtersExceptions === 'undefined') {
      this.filtersExceptions = this.http.get<string[]>(this.API_URL, {
        params: {
          action: 'getFiltersExceptions'
        },
        responseType: 'json'
      });
    }

    return this.filtersExceptions;
  }

  public getExceptionsData(supplier: string, active, filter: string, type: string) {
    return this.http.get<Exceptions[]>(this.API_URL, {
      params: {
        action: 'getExceptionsData',
        supplier: encodeURIComponent(supplier),
        active: `${active}`,
        filter: encodeURIComponent(filter),
        type: encodeURIComponent(type),
      },
      responseType: 'json'
    });
  }

  public addException(data: Exceptions) {
    return this.http.post<string[]>(this.API_URL, data, {
      params: {
        action: 'addException',
      },
      responseType: 'json'
    });
  }

  public activeException(id: number, active: boolean) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'activeException',
        id: `${id}`,
        active: `${active}`,
      },
      responseType: 'json'
    });
  }

  public updateExceptionValue(id: number, value: any) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'updateExceptionValue',
        id: `${id}`,
        value: encodeURIComponent(value),
      },
      responseType: 'json'
    });
  }

  public deleteException(id: number) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'deleteException',
        id: `${id}`,
      },
      responseType: 'json'
    });
  }

  public getLinkOdoo(id_product: number) {
    return this.http.get<string>(this.API_URL, {
      params: {
        action: 'getLinkOdoo',
        id_product: `${id_product}`,
      },
      responseType: 'json'
    });
  }

  public getOdooProducts(supplier: string, active, filter: string, type: string) {
    return this.http.get<OdooProduct[]>(this.API_URL, {
      params: {
        action: 'getOdooProducts',
        supplier: encodeURIComponent(supplier),
        active: `${active}`,
        filter: encodeURIComponent(filter),
        type: encodeURIComponent(type),
      },
      responseType: 'json'
    });
  }

  public addOdooProducts(data: OdooProduct) {
    return this.http.post<string[]>(this.API_URL, data, {
      params: {
        action: 'addOdooProducts',
      },
      responseType: 'json'
    });
  }

  public activeOdooProducts(id: number, active: boolean) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'activeOdooProducts',
        id: `${id}`,
        active: `${active}`,
      },
      responseType: 'json'
    });
  }

  public deleteOdooProducts(id: number) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'deleteOdooProducts',
        id: `${id}`,
      },
      responseType: 'json'
    });
  }

  public getTypesOdooProducts() {
    if (typeof this.typesOdooProducts === 'undefined') {
      this.typesOdooProducts = this.http.get<string[]>(this.API_URL, {
        params: {
          action: 'getTypesOdooProducts'
        },
        responseType: 'json'
      });
    }

    return this.typesOdooProducts;
  }

  public getFieldsProduct() {
    if (typeof this.fieldsProduct === 'undefined') {
      this.fieldsProduct = this.http.get<string[]>(this.API_URL, {
        params: {
          action: 'getfieldsProduct'
        },
        responseType: 'json'
      });
    }

    return this.fieldsProduct;
  }

  public createNewRule(rule_name: string) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'createNewRule',
        rule_name: encodeURIComponent(rule_name),
      },
      responseType: 'json'
    });
  }

  public getControls(offset: number, limit: number, supplier: string, date: string, orderBy: string, orderWay: string) {
    return this.http.get<ControlLoadData>(this.API_URL, {
      params: {
        action: 'getControls',
        offset: `${offset}`,
        limit: `${limit}`,
        supplier: encodeURIComponent(supplier),
        date: encodeURIComponent(date),
        orderBy: encodeURIComponent(orderBy),
        orderWay: encodeURIComponent(orderWay)
      },
      responseType: 'json'
    });
  }

  public deleteControl(id: number) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'deleteControl',
        id: `${id}`,
      },
      responseType: 'json'
    });
  }

  public getGraph(data: ProductItem[], dateStart: string, dateEnd: string) {
    return this.http.post<Graphs>(this.API_URL, JSON.stringify(data), {
      params: {
        action: 'getGraph',
        dateStart,
        dateEnd,
      },
      responseType: 'json'
    });
  }

  public getConfig(key: string) {
    return this.http.get<string>(this.API_URL, {
      params: {
        action: 'getConfig',
        key: key
      },
      responseType: 'json'
    });
  }

  public updateConfig(key: string, value: string) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'updateConfig',
        key: key,
        value: encodeURIComponent(value),
      },
      responseType: 'json'
    });
  }

  public createNewProduct(id: number, ref_supplier: string) {
    return this.http.get<number>(this.API_URL, {
      params: {
        action: 'createNewProduct',
        id: `${id}`,
        ref_supplier: encodeURIComponent(ref_supplier),
      },
      responseType: 'json'
    });
  }

  public createProductInOdoo(id: number, id_product: number) {
    return this.http.get<any[]>(this.API_URL, {
      params: {
        action: 'createProductInOdoo',
        id: `${id}`,
        id_product: `${id_product}`
      },
      responseType: 'json'
    });
  }

  public updateImage(id_product: number, url_image: string) {
    return this.http.get<number>(this.API_URL, {
      params: {
        action: 'updateImage',
        id_product: `${id_product}`,
        url_image: encodeURIComponent(url_image),
      },
      responseType: 'json'
    });
  }

  public isPro() {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'isPro',
      },
      responseType: 'json'
    });
  }

  public getAllEmployees() {
    if (typeof this.employees === 'undefined') {
      this.employees = this.http.get<Employee[]>(this.API_URL, {
        params: {
          action: 'getAllEmployees'
        },
        responseType: 'json'
      });
    }

    return this.employees;
  }

  public getAllProjects() {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'getAllProjectsOfControlEmployees'
      },
      responseType: 'json'
    });
  }

  public getDataControlEmployees(employeeId: number, filter: string, orderWay: string, offset: number, limit: number, orderBy: string, project: string, deleted: boolean, date: string) {
    return this.http.get<LoadData<ControlEmployee>>(this.API_URL, {
      params: {
        action: 'getDataControlEmployees',
        employeeId: `${employeeId}`,
        filter: encodeURIComponent(filter),
        orderWay: encodeURIComponent(orderWay),
        offset: `${offset}`,
        limit: `${limit}`,
        orderBy: encodeURIComponent(orderBy),
        project: encodeURIComponent(project),
        date: encodeURIComponent(date),
        deleted: encodeURIComponent(deleted),
      },
      responseType: 'json'
    });
  }

  public getSuppliersTarifa() {
    return this.http.get<SupplierTarifa[]>(this.API_URL, {
      params: {
        action: 'getSuppliersTarifa',
      },
      responseType: 'json'
    });
  }

  public addSupplierTarifa(data: SupplierTarifa) {
    return this.http.post<boolean>(this.API_URL, data, {
      params: {
        action: 'addSupplierTarifa',
      },
      responseType: 'json'
    });
  }

  public searchOdoo(filter: string, search_type: string) {
    return this.http.get<SearchOdooAutocomplete>(this.API_URL, {
      params: {
        action: 'searchOdoo',
        filter,
        search_type
      },
      responseType: 'json'
    });
  }

  public addFileTarifa(data, id_supplier: number, prefix: string, update_ean: boolean, update_price: boolean, update_whosale_price: boolean, update_category: boolean, dto: number) {
    return this.http.post<boolean>(this.API_URL, data, {
      params: {
        action: 'addFileTarifa',
        id_supplier: `${id_supplier}`,
        prefix: encodeURIComponent(prefix),
        update_ean: encodeURIComponent(update_ean),
        update_price: encodeURIComponent(update_price),
        update_whosale_price: encodeURIComponent(update_whosale_price),
        update_category: encodeURIComponent(update_category),
        dto: `${dto}`,
      },
      responseType: 'json'
    });
  }

  public updateFileTarifa(id_file: number, prefix: string, update_ean: boolean, update_price: boolean, update_whosale_price: boolean, update_category: boolean, dto: number) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'updateFileTarifa',
        id_file: `${id_file}`,
        prefix: encodeURIComponent(prefix),
        update_ean: encodeURIComponent(update_ean),
        update_price: encodeURIComponent(update_price),
        update_whosale_price: encodeURIComponent(update_whosale_price),
        update_category: encodeURIComponent(update_category),
        dto: `${dto}`,
      },
      responseType: 'json'
    });
  }

  public getFilesTarifa(id_supplier: number) {
    return this.http.get<FileTarifa[]>(this.API_URL, {
      params: {
        action: 'getFilesTarifa',
        id_supplier: `${id_supplier}`,
      },
      responseType: 'json'
    });
  }

  public getHeaders(id_file: number) {
    return this.http.get<HeaderTarifa[]>(this.API_URL, {
      params: {
        action: 'getHeaders',
        id_file: `${id_file}`,
      },
      responseType: 'json'
    });
  }

  public getDataTarifa(id_supplier: number, id_file: number) {
    return this.http.get<any[]>(this.API_URL, {
      params: {
        action: 'getDataTarifa',
        id_supplier: `${id_supplier}`,
        id_file: `${id_file}`,
      },
      responseType: 'json'
    });
  }

  public getHeaderTarifa(id_file: number, id_header: number = 0) {
    return this.http.get<HeaderTarifa | HeaderTarifa[]>(this.API_URL, {
      params: {
        action: 'getHeaderTarifa',
        id_file: `${id_file}`,
        id_header: `${id_header}`,
      },
      responseType: 'json'
    });
  }

  public addHeaderTarifa(data) {
    return this.http.get<number>(this.API_URL, {
      params: {
        action: 'addHeaderTarifa',
        id_file: data.id_file,
        id_header: data.id_header,
        header: encodeURIComponent(data.header),
        new_header: encodeURIComponent(data.new_header)
      },
      responseType: 'json'
    });
  }

  public getHeaderTarifaById(id_file: number, id_header: number) {
    return this.http.get<HeaderTarifa>(this.API_URL, {
      params: {
        action: 'getHeaderTarifa',
        id_file: `${id_file}`,
        id_header: `${id_header}`,
      },
      responseType: 'json'
    });
  }

  public updateSupplierTarifa(data) {
    return this.http.post<boolean>(this.API_URL, data, {
      params: {
        action: 'updateSupplierTarifa',
      },
      responseType: 'json'
    });
  }
};
