<form class="form" [formGroup]='form'>
  <div class="autocomplete">
      <mat-form-field class="filter-input">
          <input [placeholder]="placeholder" matInput [matAutocomplete]="autoSearch" formControlName='autocompleteSearch' class="input-autocomplete">
      </mat-form-field>
      <mat-autocomplete autoActiveFirstOption #autoSearch="matAutocomplete" [displayWith]="displayName" (optionSelected)="onSelect($event)">
          <mat-option *ngIf="isLoadingProducts" class="is-loading">
              <mat-spinner diameter="30"></mat-spinner>
          </mat-option>
          <ng-container *ngIf="!isLoadingProducts">
              <mat-option *ngFor="let item of data" [value]="item" title="{{item.name}}" [innerHTML]="item.name | highlight: toHighlight"></mat-option>
          </ng-container>
      </mat-autocomplete>
  </div>
</form>
