<h2 mat-dialog-title>Proveedor</h2>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Nombre de proveedor</mat-label>
    <input matInput [(ngModel)]="data.name" />
  </mat-form-field>

  <app-search-odoo [value_selected]="data.categ" [search_type]="'product.category'" (valueChange) = "updateSearch($event, 'categ_id')" [placeholder]="'Buscar categoría en odoo'"></app-search-odoo>
  <app-search-odoo [value_selected]="data.brand" [search_type]="'product.brand'" (valueChange) = "updateSearch($event, 'brand_id')" [placeholder]="'Buscar marca en odoo'"></app-search-odoo>
  <app-search-odoo [value_selected]="data.supplier" [search_type]="'res.partner'" (valueChange) = "updateSearch($event, 'supplier_id')" [placeholder]="'Buscar proveedor en odoo'"></app-search-odoo>

  <mat-form-field>
    <mat-label>Busqueda en odoo</mat-label>
    <input matInput [(ngModel)]="data.search_odoo" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Busqueda en prestashop</mat-label>
    <input matInput [(ngModel)]="data.search_prestashop" />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="addNew()" cdkFocusInitial *ngIf="data.id < 1">Crear</button>
  <button mat-button (click)="update()" cdkFocusInitial *ngIf="data.id > 0">Actualizar</button>
</div>
