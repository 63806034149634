
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Locale
import localeEs from '@angular/common/locales/es';

// Platform Browser
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { APP_ROUTING } from './app.routes';

// Services
import { ApiService } from './services/api.service';
import { AlertService } from './services/alert.service';

// Pipes
import { HighlightPipe } from './pipes/highlight.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

// Components
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { ListProductsComponent } from './list-products/list-products.component';
import { ProductsComponent } from './products/products.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TableComponent } from './components/table/table.component';
import { LogsListComponent } from './logs-list/logs-list.component';
import { BrandsComponent } from './brands/brands.component';
import { RulesListComponent } from './rules-list/rules-list.component';
import { ShowPricesComponent } from './show-prices/show-prices.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { ParentsComponent } from './parents/parents.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { SupplierBrandsComponent } from './supplier-brands/supplier-brands.component';
import { OptionsSupplierComponent } from './options-supplier/options-supplier.component';
import { CheckComponent } from './check/check.component';
import { SearchBrandComponent } from './components/search-brand/search-brand.component';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { EditDataComponent } from './components/edit-data/edit-data.component';
import { ControlComponent } from './control/control.component';
import { GraphComponent } from './components/graph/graph.component';
import { PricesComponent } from './prices/prices.component';
import { ConfigComponent } from './config/config.component';
import { OdooProductsComponent } from './odoo-products/odoo-products.component';
import { ControlEmployeesComponent } from './control-employees/control-employees.component';
import { DataComponent } from './tarifa/data/data.component';
import { SupplierComponent } from './tarifa/supplier/supplier.component';
import { SearchOdooComponent } from './tarifa/search-odoo/search-odoo.component';
import { FileComponent } from './tarifa/file/file.component';
import { HeaderComponent } from './tarifa/header/header.component';
import { InfoComponent } from './tarifa/info/info.component';

registerLocaleData(localeEs, 'es')

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    ListProductsComponent,
    ProductsComponent,
    AutocompleteComponent,
    HighlightPipe,
    TruncatePipe,
    TableComponent,
    LogsListComponent,
    BrandsComponent,
    RulesListComponent,
    ShowPricesComponent,
    SuppliersComponent,
    ParentsComponent,
    InlineEditComponent,
    SupplierBrandsComponent,
    OptionsSupplierComponent,
    CheckComponent,
    SearchBrandComponent,
    ExceptionsComponent,
    EditDataComponent,
    ControlComponent,
    GraphComponent,
    PricesComponent,
    ConfigComponent,
    OdooProductsComponent,
    ControlEmployeesComponent,
    DataComponent,
    SupplierComponent,
    SearchOdooComponent,
    FileComponent,
    HeaderComponent,
    InfoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    APP_ROUTING,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    MatButtonModule,
    ScrollingModule,
    MatCardModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    SatPopoverModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
  ],
  providers: [ApiService, AlertService, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: LOCALE_ID, useValue: 'es-Ar' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
