import { Component, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart } from 'chart.js';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { ProductItem } from 'src/app/types/product-item-model';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent {
  canvasWholesalePrice: any;
  canvasStock: any;
  ctxWholesalePrice: any;
  canvasPrice: any;
  ctxStock: any;
  ctxPrice: any;
  @ViewChild('wholesalePrice') wholesalePrice;
  @ViewChild('price') price;
  @ViewChild('stock') stock;

  @Input() data: ProductItem[];
  @Input() open: boolean;

  dateStart = new FormControl();
  dateEnd = new FormControl();
  isLoadingData = false;
  charts = [];

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  load() {
    if (typeof this.canvasWholesalePrice == 'undefined') {
      this.canvasWholesalePrice = this.wholesalePrice.nativeElement;
      this.ctxWholesalePrice = this.canvasWholesalePrice.getContext('2d');
    }

    if (typeof this.canvasPrice == 'undefined') {
      this.canvasPrice = this.price.nativeElement;
      this.ctxPrice = this.canvasPrice.getContext('2d');
    }

    if (typeof this.canvasStock == 'undefined') {
      this.canvasStock = this.stock.nativeElement;
      this.ctxStock = this.canvasStock.getContext('2d');
    }

    this.isLoadingData = true;

    var dateStart = '';
    if (this.dateStart.value != null) {
      dateStart = this.dateStart.value.toISOString();

    }

    var dateEnd = '';
    if (this.dateEnd.value != null) {
      dateEnd = this.dateEnd.value.toISOString();
    }

    this.apiService.getGraph(this.data, dateStart, dateEnd).subscribe((chart) => {
      if (chart['error']) {
        this.isLoadingData = false;
        return this.alertService.error(chart['error']);
      }

      this.loadChart(chart.wholesalePrice, this.ctxWholesalePrice, 'Precio de compra');
      this.loadChart(chart.price, this.ctxPrice, 'Precio de venta');
      this.loadChart(chart.stock, this.ctxStock, 'Stock');

      this.isLoadingData = false;
    })
  }

  loadChart(chart, ctx, title: string) {
    if (!chart.length) {
      return this.alertService.error('No se encuentran datos  de ' + title);
    }

    var newChart = chart.map((p) => {
      p.data.map((a) => {
        a.x = new Date(a.x);
      })

      return p;
    })

    var yAxexConfig = {};

    if (title == 'Stock') {
      yAxexConfig = {
        id: 'y',
        display: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          userCallback: function (tick) {
            return tick.toString();
          }
        }
      };
    } else {
      yAxexConfig = {
        id: 'y',
        display: true,
        type: 'linear',
        ticks: {
          beginAtZero: true,
          userCallback: function (tick) {
            return tick.toString() + '€';
          }
        }
      };
    }

    if (typeof this.charts[title] == 'undefined') {
      this.charts[title] = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: newChart
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: title,
            fontSize: 20,
          },
          scales: {
            xAxes: [{
              id: 'x',
              distribution: 'linear',
              position: 'bottom',
              type: 'time',
              display: true,
              time: {
                minUnit: 'day',
                displayFormats: {
                  millisecond: 'h:mm:ss.SSS a',
                  second: 'h:mm:ss a',
                  minute: 'h:mm a',
                  hour: 'hA',
                  day: 'MMM D',
                  week: 'll',
                  month: 'MMM YYYY',
                  quarter: '[Q]Q - YYYY',
                  year: 'YYYY'
                },
              }
            }],
            yAxes: [yAxexConfig]
          },
          series: [
            {
              type: 'line',
              xKey: 'date',
              yKey: 'temp',
            },
          ]
        }
      });
    } else {
      this.charts[title].data.datasets = newChart;
      this.charts[title].update();
    }
  }

  clearStartDate(val) {
    val.setValue(null);
  }
}

