<mat-toolbar color="secundary" class="app-toolbar">
  <app-options-supplier (selectSupplier)="changeSupplier($event, reference)" [discards]="[]" [news]="[{id: 0, name: 'todos', id_odoo: 0, id_cat_odoo: 0, id_ps: 0},{id: 0, name: 'hoy', id_odoo: 0, id_cat_odoo: 0, id_ps: 0}]" [supplier]="supplier"></app-options-supplier>
  <span class="app-toolbar-filler"></span>
  <mat-form-field class="reference">
    <mat-label>Referencia</mat-label>
    <input matInput type="text" [(ngModel)]="reference">
    <button *ngIf="reference" matSuffix mat-icon-button aria-label="Clear" (click)="reference=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-icon id="search" (click)="changeSupplier(supplier, reference, true)">search</mat-icon>
  <span class="app-toolbar-filler"></span>
  <button mat-button (click)="changeSupplier(supplier, reference, true)"><mat-icon>refresh</mat-icon>Recargar</button>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" opened class="drawer">
      <div class="num-products">Logs: {{ds.length}}</div>
      <div class="list">
          <cdk-virtual-scroll-viewport itemSize="51" class="viewport" uiVirtualScrollViewportResize>
              <div mat-list-item *cdkVirtualFor="let item of ds" class="item-viewport" [class.active]="item === itemSelected">
                  <div mat-line class="title-cdk" (click)="setFile(item)">
                      <div *ngIf="item">
                          <div class="item-name">
                              {{item}}
                          </div>
                      </div>
                      <div *ngIf="!item">
                          Loading...
                      </div>
                  </div>
              </div>
          </cdk-virtual-scroll-viewport>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <mat-progress-bar mode="indeterminate" *ngIf="showProgressBar"></mat-progress-bar>
      <div *ngIf="log">
        <pre>
          {{log | json}}
        </pre>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
