<mat-toolbar color="secundary" class="app-toolbar">

  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(true)">add_box</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field">
    <mat-label>Proveedor</mat-label>
    <mat-select (selectionChange)="changeSupplier($event)" [value]="supplier">
      <mat-option *ngFor="let option of suppliers" [value]="option">{{option.name | titlecase}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && supplier.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(false)">update</mat-icon>

  <span class="app-toolbar-filler"></span>
  
  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir archivo'" class="toolbar-icon" (click)="openTarifa(true)" *ngIf="supplier">add_box</mat-icon>
  <mat-form-field appearance="standard" class="small-form-field" *ngIf="supplier && supplier.id > 0">
    <mat-label>Archivo</mat-label>
    <mat-select (selectionChange)="changeFile($event)" [value]="file">
      <mat-option *ngFor="let option of files" [value]="option">{{option.file}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && file && file.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar archivo'" class="toolbar-icon" (click)="openTarifa(false)">update</mat-icon>
  
  <span class="app-toolbar-filler"></span>
  <mat-icon (click)="openInfo()">info</mat-icon>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-spinner class="my-spinner" *ngIf="isLoading"></mat-spinner>

    <table mat-table [dataSource]="data" class="data-table" *ngIf="data && !isLoading">
      <ng-container [matColumnDef]="column" *ngFor="let column of header; let i = index">
        <th mat-header-cell *matHeaderCellDef (click)="openColumn(i, column)"> {{column}} </th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element[column]"> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="header"></tr>
      <tr mat-row *matRowDef="let row; columns: header;"></tr>
    </table>
  </mat-drawer-container>
</mat-sidenav-container>
